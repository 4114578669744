import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { environment } from "src/environments/environment";
import { RegisterForm } from "../shared/interfaces/register-form";
import { RegisterService } from "../shared/services/register-service/register.service";


@Injectable({
    providedIn: 'root'
})
export class EstablishmentService {
	public backend_url = environment.BACKEND_URL;
	public pageId;
	public form = {};
	public formPayload = {};

    constructor(
		private http: HttpClient,
		private registerService: RegisterService
	){}
	
    getform(): RegisterForm {
		return this.form
    }

    updateForm(data: object) {
		this.form = Object.assign(this.form, data);
		this.registerService.updateForm(data);
	}

    clearForm() {
		localStorage.removeItem('');
	}

	addMenu(event){
		let url = `${this.backend_url}noauth/customer-register/upload-image`
		return this.http.post(url, event)
	}

	deleteMenu(event){
		let url = `${this.backend_url}noauth/customer-register/delete-image`
		return this.http.post(url, event)
	}

	// getAllSegments() {
	// 	const url = `${this.url}/auth/establishment/segments/get-all-segments`;
	// 	return this.http.get(url);
	// }
}