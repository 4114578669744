import { AbstractControl, Validators } from '@angular/forms';

export class CpfCnpjValidator {
  constructor() { }
  /**
   * Valida se o CPF/CNPJ é valido. Deve-se ser informado o número sem máscara.
  */
  static validCpfCnpj() {
    return (control: AbstractControl): Validators => {
      const cpfCnpj = control.value;
      if (cpfCnpj) {
        let numbers, digits, sum, i, result, equalDigits, length, pos;
        equalDigits = 1;

        if (cpfCnpj.length !== 11 && cpfCnpj.length < 14) {
          return null;
        }

        for (i = 0; i < cpfCnpj.length - 1; i++) {
          if (cpfCnpj.charAt(i) !== cpfCnpj.charAt(i + 1)) {
            equalDigits = 0;
            break;
          }
        }

        if(equalDigits) return { cpfCnpjInvalid: true };

        //CNPJ
        if (cpfCnpj.length === 14) {
          length = cpfCnpj.length - 2;
          numbers = cpfCnpj.substring(0, length);
          digits = cpfCnpj.substring(length);
          sum = 0;
          pos = length - 7;

          for (let i = length; i >= 1; i--) {
            sum += numbers.charAt(length - i) * pos--;
            if (pos < 2) pos = 9;
          }

          result = sum % 11 < 2 ? 0 : 11 - sum % 11;
          if (result != digits.charAt(0)) return { cpfCnpjInvalid: true };

          length = length + 1;
          numbers = cpfCnpj.substring(0, length);
          sum = 0;
          pos = length - 7;

          for (let i = length; i >= 1; i--) {
            sum += numbers.charAt(length - i) * pos--;
            if (pos < 2) pos = 9;
          }
          
          result = sum % 11 < 2 ? 0 : 11 - sum % 11;
          if (result != digits.charAt(1)) return { cpfCnpjInvalid: true };

          return null;
        } else {
          //CPF
          numbers = cpfCnpj.substring(0, 9);
          digits = cpfCnpj.substring(9);
          sum = 0;
          for (i = 10; i > 1; i--) {
            sum += numbers.charAt(10 - i) * i;
          }

          result = sum % 11 < 2 ? 0 : 11 - (sum % 11);

          if (result !== Number(digits.charAt(0))) {
            return { cpfCnpjInvalid: true };
          }
          numbers = cpfCnpj.substring(0, 10);
          sum = 0;

          for (i = 11; i > 1; i--) {
            sum += numbers.charAt(11 - i) * i;
          }
          result = sum % 11 < 2 ? 0 : 11 - (sum % 11);

          if (result !== Number(digits.charAt(1))) {
            return { cpfCnpjInvalid: true };
          }
          return null;
        }
      }
      return null;
    };
  }
}