import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { RegisterForm } from "src/app/shared/interfaces/register-form";
import { AnotaLoaderService } from "src/app/shared/services/ds-services/anota-loader.service";
import { AnotaToastService } from "src/app/shared/services/ds-services/anota-toast.service";
import { RegisterService } from "src/app/shared/services/register-service/register.service";
import { EstablishmentService } from "../establishment.service";


@Component({
    selector: 'app-establishment-part-3',
    templateUrl: 'establishment-part-3.component.html',
    styleUrls: ['establishment-part-3.component.scss']
})
export class EstablishmentPart3Component implements OnInit {
    public myForm: FormGroup;
    public pageId;
    public sessionForm;
    public registerForm: RegisterForm = {};
    public file: any;
    public deleteFile: any;
    public verifyLogo: boolean;
    public loading: boolean;

    constructor(
        private registerService: RegisterService,
        private establishmentService: EstablishmentService,
        private anotaLoaderService: AnotaLoaderService,
        private anotaToastService: AnotaToastService,
        private formBuilder: FormBuilder,
        private router: Router,
		private route: ActivatedRoute,
    ){
        this.pageId = this.route.snapshot.paramMap.get('id');
        this.establishmentService.pageId = this.pageId;
        this.sessionForm = this.registerService.getform();
        if(this.sessionForm?.logo != ''){
            this.file = this.sessionForm?.logo
        } else {
            this.file = '' 
        }
    }
    
    ngOnInit(){
        this.initializeForm();
    }

    initializeForm(){
        this.myForm = this.formBuilder.group({})
    }

    returnPage(){
        window.history.back()
    }

    changeLogoMenu(event: any) {

        if (event.includes('data:')) {
            const body = {
                url: event,
                caption: '',
                page_id: this.pageId
            }

            this.loading = true;
            this.anotaLoaderService.setShowLoader(this.loading);
            this.establishmentService.addMenu(body).subscribe((res: any)=>{
                if(res.success){
                    this.file = res.data;
                    this.deleteFile = res.data;
                    this.loading = false;
                    this.anotaLoaderService.setShowLoader(this.loading);
                }
            }, (err) =>{
                this.loading = false;
                this.anotaLoaderService.setShowLoader(this.loading);
            });
        } else if (!event) {
            const body = {
                url: this.deleteFile
            }
            
            this.loading = true;
            this.anotaLoaderService.setShowLoader(this.loading);
            this.establishmentService.deleteMenu(body).subscribe((res:any)=>{
                if(res.success){
                    this.loading = false;
                    this.anotaLoaderService.setShowLoader(this.loading);
                } 
            }, (err) =>{
                this.loading = false;
                this.anotaLoaderService.setShowLoader(this.loading);
                this.anotaToastService.setShowToast({
                    type: 'danger',
                    message: 'Tamanho de arquivo não suportado!'
                });
            })
        }
    }

    createForm(){
        if(this.file){
            if(Object.keys(this.myForm.controls).includes('logo')){
                this.myForm.controls.logo.setValue(this.file);
            } else{
                this.myForm.addControl('logo', new FormControl(this.file));
            }
        } else if (this.verifyLogo){
            if(Object.keys(this.myForm.controls).includes('logo')){
                this.myForm.controls.logo.setValue('');
            } else{
                this.myForm.addControl('logo', new FormControl(''));
            }
        }

        if(this.myForm.valid){
            const form = {
                logo: this.myForm.value?.logo 
            }
    
            if(this.sessionForm?.final_informations){
                this.establishmentService.updateForm(form);
                this.router.navigate(['final-informations/' + this.pageId]);
            } else {
                this.establishmentService.updateForm(form);
                this.router.navigate(['establishment/menu/' + this.pageId])
            }
            

        }
    }
}