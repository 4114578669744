<div class="flow-home">
    <div class="container">
        <div class="img-container">
            <img src="../../assets/anotaai.png" alt="anotaai">
        </div>
        <div class="title-container">
            <p>Informações sobre a entrega</p>
        </div>
        <form [formGroup]="myForm" novalidate>
            <div class="input-container">
                <div>
                    <span>Seu estabelecimento possui taxa mínima para pedidos? *</span>
                    <p style="color: #9D9D9D; font-size: 14px;">Pedidos menores que o valor da taxa não poderão ser realizados</p>
                    <br>
                    <anota-radio-group class="input-select" formControlName="order_fee">
                        <anota-radio value="Sim" >Sim</anota-radio>
                        <anota-radio value="Não" >Não</anota-radio>
                    </anota-radio-group>
                    <br>
                    <br>
                </div>
                <div *ngIf="myForm.controls.order_fee.value == 'Sim'" class="input-value">
                    <anota-input-group
                    mask="money" 
                    searchPosition="left" 
                    icon="dollar-sign" 
                    label="Valor da taxa mínima" 
                    formControlName="order_fee_value" 
                    placeholder="R$ 0,00"></anota-input-group>
                </div>
            </div>
        </form>
        <div class="button">
            <anota-button size="lg" type="outline-primary" (click)="returnPage()">Anterior</anota-button>
            <anota-button size="lg" (click)="createForm()" [disabled]="myForm.invalid || myForm.controls.order_fee.value == 'Sim' && myForm.controls.order_fee_value.value == '' ">Próximo</anota-button>
        </div>
    </div>
</div>
<app-footer class="footer"></app-footer>