import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { RegisterForm } from "src/app/shared/interfaces/register-form";
import { RegisterService } from "../shared/services/register-service/register.service";
import { SessionStorageService } from "../shared/services/session-storage/session-storage.service";


@Component({
    selector: 'app-delivery-informations-2',
    templateUrl: 'delivery-informations-2.component.html',
    styleUrls: ['delivery-informations-2.component.scss']
})
export class DeliveryInformations2Component implements OnInit {
    public myForm: FormGroup;
    public pageId;
    public sessionForm;
    public registerForm: RegisterForm = {};

    constructor(
        private registerService: RegisterService,
        private sessionStorageService: SessionStorageService,
        private formBuilder: FormBuilder,
        private router: Router,
		private route: ActivatedRoute,
    ){
        this.pageId = this.route.snapshot.paramMap.get('id');
        this.sessionStorageService.pageId = this.pageId;
        this.sessionForm = this.registerService.getform();
    }
    
    ngOnInit(){
        this.initializeForm();
    }
    
    initializeForm(){
        this.myForm = this.formBuilder.group({
            order_fee: [this.sessionForm?.order_fee || '', [ Validators.required]],
            order_fee_value: [this.sessionForm?.order_fee_value || ''],
        })
    }

    returnPage(){
        window.history.back()
    }
    
    createForm(){

        let formatOrderFeeValue:any;
        let verifyOrder = this.myForm.value?.order_fee 
        
        if(verifyOrder != 'Não'){
            formatOrderFeeValue = this.myForm.value?.order_fee_value
            let verify_value = typeof formatOrderFeeValue
            if(verify_value == 'string'){
                formatOrderFeeValue = formatOrderFeeValue.split('R$ ')[1].replace(',', '.')
                formatOrderFeeValue = parseFloat(formatOrderFeeValue)
            }else{
                formatOrderFeeValue = this.myForm.value?.order_fee_value;
            }
        } else {
            formatOrderFeeValue = 0;
        }

        if(this.myForm.valid){
            const form = {
                order_fee: this.myForm.value?.order_fee,
                order_fee_value: formatOrderFeeValue,
            }

            if(this.sessionForm?.final_informations){
                this.sessionStorageService.updateForm(form);
                this.router.navigate(['final-informations/' + this.pageId]);
            } else {
                this.sessionStorageService.updateForm(form); 
                this.router.navigate(['attendance-location/' + this.pageId]);
            }
        }
    }

}