import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { RegisterService } from '../register-service/register.service';

@Injectable({
  providedIn: 'root',
})
export class MixPanelService {
  public pageId;
  public event_name;
  public lastEvent;
  public innerWidth;
  public device_category;
  public user_agent;

  constructor(
    private http: HttpClient,
    private router: Router,
    private registerService: RegisterService
  ) {
    this.checkDevice();
  }

  checkDevice() {
    if (
      navigator.userAgent.match(/Android/i) ||
      navigator.userAgent.match(/webOS/i) ||
      navigator.userAgent.match(/iPhone/i) ||
      navigator.userAgent.match(/iPad/i) ||
      navigator.userAgent.match(/iPod/i) ||
      navigator.userAgent.match(/BlackBerry/i) ||
      navigator.userAgent.match(/Windows Phone/i)
    ) {
      this.device_category = 'mobile';
      this.user_agent = navigator.userAgent;
    } else {
      this.device_category = 'desktop';
      this.user_agent = navigator.userAgent;
    }
  }

  public startService() {
    console.log('MixPanelService started');
    this.pageId = this.registerService.pageId;
  }

  sendEvent(event_name, data:any = {}) {
   let payload = {
      event_name: `${event_name}`,
      device_category: this.device_category,
      device_category_user_agent: this.user_agent,
      variation: 'B',
      page_id: this.pageId,
      timestamp: new Date().toISOString(),
      sender: {
        type: 'FRONTEND',
        module: 'onboarding-register-frontend-variation-a',
      },
      ...data
    };
    this.http.post(environment.MIX_PANEL_TRACKER_URL, payload).subscribe(
      (res) => {
        console.log(res);
      },
      (err) => {
        console.log(err);
      }
    );
  }


}
