import { Component, OnInit, ViewChild } from "@angular/core";

@Component({
    selector: 'app-establishment',
    templateUrl: 'establishment.component.html',
    styleUrls: ['establishment.component.scss']
})
export class EstablishmentComponent implements OnInit {

    ngOnInit(){}

}