import { Component, OnInit, ViewChild, ChangeDetectionStrategy,ChangeDetectorRef } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { RegisterForm } from "src/app/shared/interfaces/register-form";
import { AnotaToastService } from "../shared/services/ds-services/anota-toast.service";
import { RegisterService } from "../shared/services/register-service/register.service";
import { SessionStorageService } from "../shared/services/session-storage/session-storage.service";
import { MapsLocationService } from "../shared/services/maps-location/maps-location.service";
import { MixPanelService } from "../shared/services/mix-panel/mix-panel.service";
import { MapGeocoder } from "@angular/google-maps";

@Component({
    selector: 'app-attendance-location',
    templateUrl: 'attendance-location.component.html',
    styleUrls: ['attendance-location.component.scss'],
    // changeDetection: ChangeDetectionStrategy.OnPush
})
export class AttendanceLocationComponent implements OnInit {
    public myForm: FormGroup;
    public pageId;
    public sessionForm;
    public registerForm: RegisterForm = {};
    public attendance: any;
    public districtList: Array<any> = [];
    public radiusList: Array<any> = [];
    public messageError = '';
    public verifyRadius = false;
    public timeout: any;
    public step: number = 2;

    map: google.maps.Map;
    map2: google.maps.Map;

    @ViewChild('map') mapElement: any;
    @ViewChild('map2') mapElement2: any;
    
    constructor(
        private registerService: RegisterService,
        private sessionStorageService: SessionStorageService,
        private mapsLocationService: MapsLocationService,
        private mixPanelService: MixPanelService,
        private anotaToastService: AnotaToastService,
        private formBuilder: FormBuilder,
        private router: Router,
		private route: ActivatedRoute,
        private cdRef:ChangeDetectorRef
    ){
        this.pageId = this.route.snapshot.paramMap.get('id');
        this.sessionStorageService.pageId = this.pageId;
        this.registerService.pageId = this.pageId;
        this.sessionForm = this.registerService.getform();
    }
    
    ngOnInit(){
        this.initializeForm();
        if(this.sessionForm.regions && !this.sessionForm.attendance_model){
            if(this.sessionForm.regions.length != 0){
                let regions_options = [];
                let regions_verify: any;
                this.sessionForm.regions.forEach((element:any) => {
                    const options = [{
                        name: element.name,
                        value: element.price,
                        location: element.location
                    }]

                    regions_options = regions_options.concat(options)
                })

                regions_verify = regions_options[0]?.location.type.includes('Polygon')
                
                if(!regions_verify){
                    this.sessionForm.attendance_model = 'Por Bairro';

                    this.sessionForm.attendance_district = {options: regions_options};
                    this.initializeForm();
                    this.setOptionDistrict(this.sessionForm.attendance_district);
                    this.setOptionRadius({});
                } else {
                    this.sessionForm.attendance_model = 'Por Raio';

                    this.sessionForm.attendance_radius = {options: regions_options};
                    this.initializeForm();
                    this.setOptionDistrict({});
                    this.setOptionRadius(this.sessionForm.attendance_radius);
                }
            }else{
                this.initializeForm();
                this.setOptionDistrict({});
                this.setOptionRadius({});
            }

        } else {
            this.initializeForm();
            this.setOptionDistrict({});
            this.setOptionRadius({});
        }
        
    }

    ngAfterViewInit(){
        this.verifyMap();
        this.cdRef.detectChanges();
    }

    initMap() {
        const geocoder = new google.maps.Geocoder();
        const addressUnit = this.sessionForm?.address?.location?.coordinates;
        if(addressUnit){
            this.map = this.mapsLocationService.createMap(addressUnit, this.mapElement.nativeElement);
            this.mapsLocationService.markerInMap(addressUnit, this.map);
            this.createRegionRadius(this.map);

        } else {
            let coordinates: any = [];
            let address = `${this.sessionForm?.address.street}-${this.sessionForm?.address.city}`;

            if(address){
                geocoder.geocode({'address': address}, (res, status)=>{
                    if(status == google.maps.GeocoderStatus.OK){
                        coordinates.push(res[0].geometry.location.lng());
                        coordinates.push(res[0].geometry.location.lat());
                        this.sessionForm.address.location = {coordinates}
                        this.map = this.mapsLocationService.createMap(this.sessionForm.address.location.coordinates, this.mapElement.nativeElement);
                        this.mapsLocationService.markerInMap(coordinates, this.map);
                        this.createRegionRadius(this.map);
                    } else {
                        this.myForm.controls.attendance_model.setValue('');
                        this.anotaToastService.setShowToast({
                            type: 'danger',
                            message: 'Endereço não encontrado!'
                        });
                    }
                })
            }
        }
    }
    
    async createRegionRadius(map) {
        await this.radiusList[0].options.forEach(region => {
            this.mapsLocationService.createRegionRadius(region, map);
        });
    }

    async newRegionRadius(map, index) {
        let region = this.radiusList[0].options
        await this.mapsLocationService.createRegionRadius(region[index], map);
    }

    async addAddress(element, index){
        const coordinates = this.mapsLocationService.getCircle({
            lat: this.sessionForm.address.location.coordinates[1],
            lng: this.sessionForm.address.location.coordinates[0]
        }, element);

        this.mapsLocationService.removeRegionRadius(index, this.map);
        this.radiusList[0].options[index].location = {coordinates: [coordinates], type:'Polygon'}
        this.newRegionRadius(this.map, index)

    }

    formatRegions() {
        
        this.radiusList[0].options.forEach((region) => {
            if (region['polygon']) {
                
                let vertices = region['polygon'].getPath();
                // region.location = {
                //   coordinates: [[]]
                // }
                if(vertices){
                    vertices.forEach((vertice, index) => {
                        let xy = vertices.getAt(index)
                        region.location.coordinates[0].push([xy.lng(), xy.lat()])
                    })
                }
            }
    
            delete region['polygon'];
            delete region['bkp'];
            delete region['regionsInCommon'];
        })
      }
    
    initializeForm(){
        this.myForm = this.formBuilder.group({
            attendance_model: [this.sessionForm?.attendance_model || '', [ Validators.required]],
            attendance_city_value: [this.sessionForm?.attendance_city_value || ''],
            attendance_district: [{} || ''], // Dado estático!!!
            attendance_radius: [{} || ''], // Dado estático!!!
        })

    }

    setOptionDistrict(event) {
        if(!this.sessionForm.attendance_district || this.sessionForm.attendance_district.length == 0){
            this.districtList = []; 
            this.districtList = this.districtList.concat(event);
            this.districtList = this.districtList.filter((district, i) => this.districtList.indexOf(district) === i)
            this.districtList.map(district => {
                if (!district.options) district.options = [{ name: '', value: '' }];
            });
        } else {
            this.districtList = this.districtList.concat(this.sessionForm?.attendance_district);
        }
    }
    
    addOptionDistrict(district: any, indexOptions: number) {
        district.options.push({ name: '', value: '' });
        let id = document.getElementById('input-district').id = `input-district-${indexOptions+1}`;
        document.getElementById(id).scrollIntoView({behavior:'smooth'});
    }
    
    removeOptionDistrict(district: any, indexOptions: number) {
        district.options.splice(indexOptions, 1);
    }

    verifyMap(){
        if(this.myForm.get('attendance_model').value == 'Por Raio'){
            this.initMap();
            this.verifyRadius = true;
        } else{
            this.verifyRadius = false;
        }
    }

    filterValue(){
        if(this.myForm.value.attendance_model == 'Por Bairro'){
            let district_options = this.districtList.find(district => district.options)
            if(district_options.options[0].value || district_options.options[0].value == ''){
                district_options.options.forEach((element:any) => {
                    let verify_value = typeof element.value
                    if(verify_value == 'string' && element.value != ''){
                        element.value = element.value.split('R$ ')[1].replace(',', '.')
                        element.value = + parseFloat(element.value).toFixed(2)
                    }
                });
            }
        } else if (this.myForm.value.attendance_model == 'Por Raio'){
            let radius_options = this.radiusList.find(radius => radius.options);
            if(radius_options.options[0].value || radius_options.options[0].value == ''){
                radius_options.options.forEach((element:any) => {
                    let verify_value = typeof element.value
                    if(verify_value == 'string' && element.value != ""){
                        element.value = element.value.split('R$ ')[1].replace(',', '.')
                        element.value = + parseFloat(element.value).toFixed(2)
                    }
                });
            }
        } else if (this.myForm.value.attendance_model == 'Mesmo valor para qualquer localidade'){
            let formatValue:any = this.myForm.value.attendance_city_value
            let verify_value = typeof formatValue
            if(verify_value == 'string' && formatValue != ''){
                formatValue = formatValue.split('R$ ')[1].replace(',', '.')
                formatValue = + parseFloat(formatValue).toFixed(2)
                this.myForm.get('attendance_city_value').setValue(formatValue)
            } else {
                this.myForm.get('attendance_city_value').setValue(formatValue)
            }
        }
    }

    setOptionRadius(event) {
        if(!this.sessionForm.attendance_radius || this.sessionForm.attendance_radius.length == 0){
            this.radiusList = []; 
            this.radiusList = this.radiusList.concat(event);
            this.radiusList = this.radiusList.filter((day, i) => this.radiusList.indexOf(day) === i)
            this.radiusList.map(radius => {
                if (!radius.options) radius.options = [{ name: '', value: '' }];
            });
        } else {
            this.radiusList = this.radiusList.concat(this.sessionForm?.attendance_radius);
        }
        
    }
    
    addOptionRadius(radius: any, indexOptions: number) {
        radius.options.push({ name: '', value: '' });
        let id = document.getElementById('input-radius').id = `input-radius-${indexOptions+1}`;
        document.getElementById(id).scrollIntoView({behavior:'smooth'});
    }
    
    removeOptionRadius(radius: any, indexOptions: number) {
        radius.options.splice(indexOptions, 1);
        this.mapsLocationService.removeRegionRadius(indexOptions, this.map);
    }

    returnPage(){
        window.history.back()
    }
    
    createForm(){      
        if(this.myForm.value.attendance_model == 'Por Bairro'){
            let district_options = this.districtList.find(district => district.options)
            if(district_options.options[0].value || district_options.options[0].value == ''){
                district_options.options.forEach((element:any) => {
                    let verify_value = typeof element.value
                    if(verify_value == 'string' && element.value != ''){
                        element.value = element.value.split('R$ ')[1].replace(',', '.')
                        element.value = parseFloat(element.value)
                    } else if(element.value == 0 || element.value == '') {
                        element.value = 0;
                    } 
                });
            }

            if(Object.keys(this.myForm.controls).includes('attendance_district')){
                this.myForm.controls.attendance_district.setValue(district_options)
            } else{
                this.myForm.addControl('attendance_district', new FormControl(district_options) )
            }

            const form = {
                attendance_model: this.myForm.value?.attendance_model,
                attendance_district: this.myForm.value?.attendance_district,
                attendance_radius: [], 
                attendance_city_value: ''
            }

            let verify_district = this.myForm.value?.attendance_district.options.find(district => district.name == '');

            if(verify_district == undefined){
                this.messageError = ''
                if(this.sessionForm?.final_informations){
                    this.sessionStorageService.updateForm(form);
                    this.router.navigate(['final-informations/' + this.pageId]);
                } else {
                    this.sessionStorageService.updateForm(form); 
                    this.mixPanelService.sendEvent('click_fe_service_regions', {service_region_selected: 'neighborhood'});
                    this.router.navigate(['payment-informations/' + this.pageId]);
                }
            } else {
                this.anotaToastService.setShowToast({
                    type: 'danger',
                    message: 'O nome do bairro é obrigatório!'
                });
            }

        } else if (this.myForm.value.attendance_model == 'Por Raio'){
            let radius_options = this.radiusList.find(radius => radius.options);
            if(radius_options.options[0].value || radius_options.options[0].value == ''){
                radius_options.options.forEach((element:any) => {
                    delete element.polygon;
                    delete element.ngClass;
                    let verify_value = typeof element.value
                    if(verify_value == 'string' && element.value != ""){
                        element.value = element.value.split('R$ ')[1].replace(',', '.')
                        element.value = parseFloat(element.value)
                    } else if(element.value == 0 || element.value == "") {
                        element.value = 0;
                    }
                });
            }

            if(Object.keys(this.myForm.controls).includes('attendance_radius')){
                this.myForm.controls.attendance_radius.setValue(radius_options)
            } else{
                
                this.myForm.addControl('attendance_radius', new FormControl(radius_options) )
            }

            const form = {
                attendance_model: this.myForm.value?.attendance_model,
                attendance_district: [],
                attendance_radius: this.myForm.value?.attendance_radius, 
                attendance_city_value: '',
            }
            let verify_radius = this.myForm.value?.attendance_radius.options.find(radius => radius.name == '');

            if(verify_radius == undefined){
                this.messageError = ''
                
                if(this.sessionForm?.final_informations){
                    this.sessionStorageService.updateForm(form);
                    this.router.navigate(['final-informations/' + this.pageId]);
                } else {
                    this.sessionStorageService.updateForm(form); 
                    this.mixPanelService.sendEvent('click_fe_service_regions', {service_region_selected: 'radius'});
                    this.router.navigate(['payment-informations/' + this.pageId]);
                }
            } else {
                this.anotaToastService.setShowToast({
                    type: 'danger',
                    message: 'O raio de atendimento é obrigatório!'
                });
            }

        } else if (this.myForm.value.attendance_model == 'Mesmo valor para qualquer localidade'){
            if(this.sessionForm?.attendance_city_value){
                this.myForm.value.attendance_city_value = `R$ ${this.sessionForm.attendance_city_value.toFixed(2).replace('.',',')}`
            }

            let formatValue:any = this.myForm.value.attendance_city_value

            if(this.myForm.value.attendance_city_value != ''){
                let verify_value = typeof formatValue
                if(verify_value == 'string'){
                    formatValue = formatValue.split('R$ ')[1].replace(',', '.')
                    formatValue = parseFloat(formatValue)
                    this.myForm.get('attendance_city_value').setValue(formatValue)
                } else {
                    this.myForm.get('attendance_city_value').setValue(formatValue)
                }
                
            } else {
                formatValue = 0
                this.myForm.get('attendance_city_value').setValue(formatValue)
            }

            const form = {
                attendance_model: this.myForm.value?.attendance_model,
                attendance_district: [],
                attendance_radius: [], 
                attendance_city_value: this.myForm.value?.attendance_city_value
            }

            
            if(form.attendance_city_value || form.attendance_city_value == ''){
                this.messageError = ''
                this.sessionStorageService.updateForm(form); 
                this.mixPanelService.sendEvent('click_fe_service_regions', {service_region_selected: 'same-value-for-anywhere'});
                this.router.navigate(['payment-informations/' + this.pageId]);
                
            } else {
                this.anotaToastService.setShowToast({
                    type: 'danger',
                    message: 'O valor é obrigatório!'
                });
            }
        }
    }

}