<div class="flow-home">
    <div class="container">
        <div class="img-container">
            <img src="../../assets/anotaai.png" alt="anotaai">
        </div>
        <div class="title-container">
            <p>Endereço do seu estabelecimento</p>
        </div>
        <form [formGroup]="myForm" novalidate>
            <div class="input-container">
                <anota-input-text 
                    size="lg"
                    label="CEP"
                    placeholder="_____-___"
                    formControlName="cep"
                    (keyup)="debounce()"
                    mask="cep"
                ></anota-input-text>
                <br>
                <div class="address-container">
                    <anota-input-text 
                        size="lg"
                        label="Cidade *"
                        placeholder="Ex: São Paulo"
                        formControlName="city"
                        style="width: 99%; margin-right: 20px;"
                    ></anota-input-text>
                    <br>
                    <anota-input-text 
                        size="lg"
                        label="Estado *"
                        placeholder="Ex: SP"
                        formControlName="state"
                    ></anota-input-text>
                </div>
                <br>
                <anota-input-text 
                    size="lg"
                    label="Logradouro *"
                    placeholder="Ex: Av. Paulista"
                    formControlName="street"
                ></anota-input-text>
                <br>
                <div class="address-container">
                    <anota-input-text 
                        size="lg"
                        label="Numero *"
                        placeholder="Ex: 206A"
                        formControlName="number"
                    ></anota-input-text>
                    <br>
                    <anota-input-text 
                        size="lg"
                        label="Bairro *"
                        placeholder="Ex: Centro"
                        formControlName="district"
                        style="width: 99%; margin-left: 20px;"
                    ></anota-input-text>
                </div>
                <br>
                <anota-input-text 
                    size="lg"
                    label="Complemento"
                    placeholder="Ex: Apartamento 13"
                    formControlName="complement"
                ></anota-input-text>
                <br>
            </div>
        </form>
        <div class="button">
            <anota-button size="lg" type="outline-primary" (click)="returnPage()">Anterior</anota-button>
            <anota-button size="lg" (click)="createForm()" [disabled]="myForm.invalid">Próximo</anota-button>
        </div>
    </div>
</div>
<app-footer class="footer"></app-footer>