import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { RegisterForm } from "src/app/shared/interfaces/register-form";
import { RegisterService } from "../shared/services/register-service/register.service";
import { SessionStorageService } from "../shared/services/session-storage/session-storage.service";
import { MixPanelService } from "../shared/services/mix-panel/mix-panel.service";


@Component({
    selector: 'app-delivery-informations',
    templateUrl: 'delivery-informations.component.html',
    styleUrls: ['delivery-informations.component.scss']
})
export class DeliveryInformationsComponent implements OnInit {
    public myForm: FormGroup;
    public pageId;
    public sessionForm;
    public registerForm: RegisterForm = {};
    public messageError = '';
    public step: number = 1;


    constructor(
        private registerService: RegisterService,
        private sessionStorageService: SessionStorageService,
        private formBuilder: FormBuilder,
        private router: Router,
		private route: ActivatedRoute,
        private mixPanelService: MixPanelService
    ){
        this.pageId = this.route.snapshot.paramMap.get('id');
        this.sessionStorageService.pageId = this.pageId;
        this.sessionForm = this.registerService.getform();
    }
    
    ngOnInit(){
        this.initializeForm();
    }
    
    initializeForm(){

        this.myForm = this.formBuilder.group({
            delivery: [this.sessionForm?.delivery || true],
            take_local: [this.sessionForm?.take_local || true],
            consume_local: [this.sessionForm?.consume_local || ''], 
            quantity_tables: [this.sessionForm?.quantity_tables || 0],
            waiter_tip_percent: [this.sessionForm?.waiter_tip_percent || 0]
        })

    }

    returnPage(){
        window.history.back()
    }

    localConsuption(){
        let id = this.myForm.value?.consume_local ? document.getElementById('consuption').id : document.getElementById('check').id;
        document.getElementById(id).scrollIntoView({behavior:'smooth'});
    }
    
    createForm(){
        const form = {
            delivery: this.myForm.value?.delivery,
            take_local: this.myForm.value?.take_local,
            consume_local: this.myForm.value?.consume_local,
            quantity_tables: this.myForm.value?.quantity_tables,
            waiter_tip_percent: this.myForm.value?.waiter_tip_percent
        }

        if(form.consume_local && form.quantity_tables == 0){
            this.messageError = "Preencha com a quantidade de mesas do seu salão "
        } else {
            this.messageError = "";
            let delivery_methods_selected = []
    
            for(let i in form){
                if(form[i] == true){
                    delivery_methods_selected.push(i)
                }
            }
    
            this.mixPanelService.sendEvent('click_fe_delivery_methods', {delivery_methods_selected: delivery_methods_selected})
    
            this.sessionStorageService.updateForm(form); 
            this.router.navigate(['attendance-location/' + this.pageId]);
        }

    }

}