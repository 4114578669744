import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Toast } from '../../interfaces/toast.interface';

@Injectable({ providedIn: 'root' })
export class AnotaToastService {
  showToast = new BehaviorSubject<Toast>({
    show: false,
    type: 'success',
    message: ''
  });

  setShowToast(value: Toast) {
    value.show = true;
    this.showToast.next(value);
  }

  getShowToast() {
    return this.showToast.asObservable();
  }
}