<div class="flow-home">
    <div class="container">
        <div class="img-container">
            <img src="../../assets/anotaai.png" alt="anotaai">
        </div>
        <div class="title-container">
            <p>Dados do cardápio de pizza</p>
        </div>
        <div class="subtitle-container">
            <span>Selecione os tamanhos de pizza disponíveis em seu cardápio</span>
        </div>
        <div class="input-container">
            <div *ngFor="let pizza of pizza">
                <div >
                    <anota-checkbox [(ngModel)]="pizza.selected">{{pizza.title}}</anota-checkbox>
                    <br>
                </div>
                <div *ngIf="pizza.selected == true"  >
                    <div class="pizza-selected">
                        <div class="pizza-options">
                            <span>Numero de Fatias * </span>
                            <div>
                                <anota-input-number
                                    [messageError]="messageError"
                                    [(ngModel)]="pizza.amount_slice"
                                ></anota-input-number>
                            </div>
                        </div>
                        <div class="pizza-options">
                            <span>Até quantos sabores por pizza? *</span>
                            <div>
                                <anota-input-number
                                    [messageError]="messageError"
                                    [(ngModel)]="pizza.amount_flavor"
                                ></anota-input-number>
                            </div>
                        </div>
                    </div>
                    <br>
                    <br>
                </div>
        </div>
        <div class="button">
            <anota-button size="lg" type="outline-primary" (click)="returnPage()">Anterior</anota-button>
            <anota-button size="lg"  (click)="createForm()">Próximo</anota-button>
        </div>
    </div>
</div>
<app-footer class="footer"></app-footer>