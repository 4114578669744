<div class="flow-home">
    <div class="container">
        <div class="img-container">
            <img src="../../assets/logo.svg" style="height: 40px;" alt="anotaai">
        </div>

        <app-progress-bar [step]="step"></app-progress-bar>

        <div class="img-container">
            <img src="../../assets/Group.svg" alt="anotaai" style="max-width: 130px;">
        </div>
        <div class="title-container">
            <p>Cadastro realizado com sucesso!</p>
        </div>
        <div class="subtitle-container">
            Suas respostas foram salvas. Nosso time de ativação entrará em contato em até 48 horas úteis para alinhar os próximos passos.
        </div>

        <div class="button-container">
            <anota-button size="lg" (click)="direct()">
                Explorar o sistema
            </anota-button>
        </div>
    </div>
</div>