import { Injectable } from "@angular/core";
import { RegisterForm } from "../../interfaces/register-form";


@Injectable({
    providedIn: 'root'
})
export class SessionStorageService {
	public pageId;
	public form = {};
    
    constructor(){}
    
    getform(): RegisterForm {
		let stored = localStorage.getItem("form");
		this.form = JSON.parse(stored);
		if(this.form == null){
			return this.form = {}
		} else {
			return this.form
		}

	}

    updateForm(data: object) {
		this.form = Object.assign(this.form, data);
		localStorage.setItem('form', JSON.stringify(this.form));
	}

    clearForm() {
		localStorage.removeItem('form');
	}
}