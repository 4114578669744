import { Injectable } from "@angular/core";
import { } from '@angular/google-maps';

@Injectable({ providedIn: 'root' })
export class MapsLocationService {
  circleLatLngs: Array<any>;
  regionsRadius: any = [];
  heatmap: google.maps.visualization.HeatmapLayer
  editingPolygonMap: boolean = false
  point: any;
  listZoom: any = [];

  getCircle(center: any, rad: number) {
    rad = rad / 1.6;
    const d2r = Math.PI / 180;
    this.circleLatLngs = new Array();
    const circleLat = (rad / 3963.189) / d2r;
    const circleLng = circleLat / Math.cos(center.lat * d2r);

    for (let i = 0; i < 361;) {
      const theta = i * d2r;
      const vertexLat = center.lat + (circleLat * Math.sin(theta));
      const vertexLng = center.lng + (circleLng * Math.cos(theta));
      const vertextLatLng = [parseFloat(vertexLng), parseFloat(vertexLat)];
      this.circleLatLngs.push(vertextLatLng);

      i += 15;
    }

    return this.circleLatLngs;
  }

  createMap(addressUnit: any, element: any) {
    const mapProperties = {
      center: new google.maps.LatLng(addressUnit[1], addressUnit[0]),
      zoom: 14,
      mapTypeId: google.maps.MapTypeId.ROADMAP
    };

    return new google.maps.Map(element, mapProperties);
  }

  markerInMap(coordinates: any, map: any) {
    this.point = {
      lat: coordinates[1],
      lng: coordinates[0]
    };
    const marker = new google.maps.Marker({
      position: {
        lat: coordinates[1],
        lng: coordinates[0]
      },
      map: map,
    });
  }

  heatMap(map, point) {
    this.heatmap = new google.maps.visualization.HeatmapLayer({
      data: point,
      map: map,
    });
  }

  saveCustomRegion(item) {
    item.ngClass = "box-register-region pulse-off"
    var vertices = item.polygon.getPath()
    if (vertices != undefined && vertices.length >= 3) {
      item.polygon.setOptions({ editable: false, strokeColor: "#388E3C", fillColor: "#388E3C" })
    }
    else {
      alert("clique em pelo menos 3 pontos do mapa para criar uma região")
    }
  }

  createRegionRadius(region: any, map: any) {
    this.editingPolygonMap = false;
    let vertices = region.location?.coordinates[0] ? region.location?.coordinates[0] : []
    let poly = []
    for (let j = 0; j < vertices.length; j++) {
      poly.push({ lng: vertices[j][0], lat: vertices[j][1] })
    }

    let euclid = vertices.length ? Math.sqrt((vertices[0][0] - this.point?.lng)**2 + (vertices[0][1] - this.point?.lat)**2) * 30 : null;
    this.listZoom.push(euclid)
    let zoom = 14 - Math.max(...this.listZoom)
    zoom < 10 ? map.setZoom(10) : map.setZoom(zoom);

    region.polygon = new google.maps.Polygon({
      paths: poly,
      draggable: false, // turn off if it gets annoying
      editable: false,
      strokeColor: '#388E3C',
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: '#388E3C',
      fillOpacity: 0.35,
    })

    delete region.coordinates
    delete region.nodes

    region.ngClass = "box-register-region pulse-off"

    region.polygon.setMap(map);
    this.regionsRadius.push(region.polygon);
  }

  startEditRegion(region) {
    this.editingPolygonMap = true;
    region.bkp = {}
    var poly = []
    var vertices = region.polygon.getPath()
    if (vertices != undefined) {
      for (var i = 0; i < vertices.length; i++) {
        var xy = vertices.getAt(i)

        poly.push({ lat: xy.lat(), lng: xy.lng() })
      }
    }

    region.polygon.setOptions({ editable: true, fillColor: "#FF0000", strokeColor: "#FF0000" })

    region.ngClass = "box-register-region pulse-off"
  }

  removeRegionRadius(index: number, map:any) {
    this.regionsRadius[index] ? this.regionsRadius[index].setMap(null) : this.regionsRadius[index]
    this.regionsRadius.splice(index, 1);
    this.listZoom.splice(index, 1);

    let zoom = 14 - Math.max(...this.listZoom)
    zoom < 10 ? map.setZoom(10) : map.setZoom(zoom);
  }

  updateRegionRadius(index: number, region: any, map: any) {
    this.removeRegionRadius(index, map);
    this.createRegionRadius(region, map);
  }


}