<div class="flow-home">
    <div class="container">
        <div class="img-container">
            <img src="../../assets/anotaai.png" alt="anotaai">
        </div>
        <br>
        <div>
            <div class="title-container">
                <p>
                    Certifico que enviei todas as informações e arquivos necessários para elaboração do meu cardápio digital e estou ciente que, 
                    entre a conclusão do preenchimento deste formulário e a instalação da plataforma, não será aceito o envio de novos itens.
                </p>
            </div>
            <div class="subtitle-container">
                <p>Estou ciente também que, após a instalação da minha plataforma, terei total autonomia para editar e incluir novos itens ao meu cardápio.</p>
            </div>
            <form [formGroup]="myForm" class="input-container">
                <anota-checkbox
                    formControlName="verify_informations"
                >Concordo</anota-checkbox>
            </form>
        </div>
        <div class="button-container">
            <anota-button size="lg" (click)="confirm()" [disabled]="myForm.invalid">Finalizar</anota-button>
        </div>
    </div>
</div>
<app-footer class="footer"></app-footer>