<div class="flow-home">
    <div class="container">
        <div class="img-container">
            <img src="../../assets/anotaai.png" alt="anotaai">
        </div>
        <div class="title-container">
            <p>Dados do seu estabelecimento</p>
        </div>
        <div class="subtitle-container">
            <span>Envie a imagem da logo do seu estabelecimento</span>
        </div>
        <div class="input-container">
            <anota-input-image
                [(ngModel)]="file"
                (anotaImageChange)="changeLogoMenu($event)" 
            ></anota-input-image>
        </div>
        <div class="input-verify">
            <anota-checkbox
                [(ngModel)]="verifyLogo"
            ></anota-checkbox>
            <span>Não possuo a logo do meu estabelecimento. Gostaria de avançar mesmo assim.</span>
        </div>
        <div class="button">
            <anota-button size="lg" type="outline-primary" (click)="returnPage()">Anterior</anota-button>
            <anota-button size="lg" (click)="createForm()" [disabled]="!file && !verifyLogo">Próximo</anota-button>
        </div>
    </div>
</div>
<app-footer class="footer"></app-footer>