import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { RegisterForm } from "src/app/shared/interfaces/register-form";
import { Segment } from "src/app/shared/interfaces/segment.interface";
import { RegisterService } from "src/app/shared/services/register-service/register.service";
import { EstablishmentService } from "../establishment.service";
import { AnotaToastService } from "src/app/shared/services/ds-services/anota-toast.service";

@Component({
    selector: 'app-establishment-part-2',
    templateUrl: 'establishment-part-2.component.html',
    styleUrls: ['establishment-part-2.component.scss']
})
export class EstablishmentPart2Component implements OnInit {
    public myForm: FormGroup;
    public pageId;
    public sessionForm;
    public registerForm: RegisterForm = {};
    public file: any;
    public listSegment: Array<any> = [];
    public otherSegment: Boolean = false;

    public Segments = new Map ([
        [1, {option: 'Hamburgueria e Lancheria', checked: false}],
        [2, {option: 'Salgaderia', checked: false}],
        [3, {option: 'Marmitaria e Restaurante', checked: false}],
        [4, {option: 'Comida Japonesa', checked: false}],
        [5, {option: 'Pizzaria', checked: false}],
        [6, {option: 'Açaiteria', checked: false}],
        [7, {option: 'Distribuidora de Bebidas', checked: false}],
        [8, {option: 'Sorveteria', checked: false}],
        [9, {option: 'Outro', checked: false}]
    ])

    constructor(
        private registerService: RegisterService,
        private establishmentService: EstablishmentService,
        private anotaToastService: AnotaToastService,
        private formBuilder: FormBuilder,
        private router: Router,
		private route: ActivatedRoute,
    ){
        this.pageId = this.route.snapshot.paramMap.get('id');
        this.establishmentService.pageId = this.pageId;
        this.sessionForm = this.registerService.getform();
    }
    
    ngOnInit(){
        this.initializeForm();

        if(this.sessionForm?.segment && this.sessionForm?.segment.length != 0){
            this.Segments.forEach((element)=>{
                this.sessionForm.segment.forEach((segments)=>{
                    if(element.option == segments.option){
                        element.checked = true;
                    }
                })
            })

            this.initSegments();
        } else {
            this.initSegments();
        }

        // find((element) => element.name === segment.name);
        // if(segments){
        //     segments.forEach((segment) => {
        //         const index = this.listSegment.
        //         if(index > -1){
        //             this.listSegment[index].value = true;
        //         }
        //         if(segment.option === 'Outros'){
        //             this.otherSegment = true;
        //         }
        //         if(index == -1){
        //             this.listSegment.push(segment);
        //         }
        //     })
        // }

    }

    initializeForm(){

        this.myForm = this.formBuilder.group({
            phone: [ this.sessionForm.phone || '', [
                Validators.required,
                Validators.minLength(11),
            ]],
            // segment: [ this.sessionForm.segment || [], [
            //     Validators.required,
            // ]],
            otherSegment: [''],
        })
    }

    returnPage(event){
        if(event.pointerType === 'mouse'){
            window.history.back()
        }
    }

    // buildSegmentsOptions(segments: Segment[]) {
    //     const segment = segments;
    //     segment.forEach((segment, index) => {
    //         this.listSegment.push(
    //             {
    //                 option: segment.name,
    //                 infos: {
    //                     id: segment.segment_id
    //                 },
    //                 checked: false,
    //             }
    //         )
    //         // this.pageSegments.forEach((pageSegment) => {
    //         // if(pageSegment.segment_id === segment.segment_id) {
    //         //     this.options[index].checked = true
    //         // }
    //         // })
    //     })
    //     return this.listSegment;
    // }

    initSegments() {
        if(!this.sessionForm?.segment || this.sessionForm?.segment.length == 0){
            this.listSegment = [];
    
            for (let i = 1; i <= this.Segments.size; i++) {
                const marketplace = {...this.Segments.get(i)};
                marketplace.checked = false;
                this.listSegment.push(marketplace);
            }
        } else {
            for (let i = 1; i <= this.Segments.size; i++) {
                const marketplace = {...this.Segments.get(i)};
                this.listSegment.push(marketplace);
            }
        }
    }

    setSeg(segment){
        for (let i = 0; i <= this.Segments.size; i++) {
            const marketplace = {...this.Segments.get(i)};
            if(marketplace.option == segment.option){
                this.listSegment[i-1].checked == false ? this.listSegment[i-1].checked = true : this.listSegment[i-1].checked = false
            }
        }
        // this.segmentChange()

    }

    createForm(){
        var segments = this.listSegment.filter(element => element.checked== true)
        const verify = segments.find(element => element.checked == true)

        if(verify){
            if(Object.keys(this.myForm.controls).includes('segment')){
                this.myForm.controls.segment.setValue(segments);
            } else{
                this.myForm.addControl('segment', new FormControl(segments));
            }
    
            if(this.myForm.valid){
                if(this.myForm.value?.otherSegment){
                    this.myForm.value?.segment.push({option: this.myForm.value?.otherSegment, checked: true})
                }
                const form = {
                    phone: this.myForm.value?.phone,
                    segment: this.myForm.value?.segment
                }
                
                const segmentPizza = form.segment?.find( element => element.option =='Pizzaria');
                
                
                if(this.sessionForm?.final_informations){
                    this.establishmentService.updateForm(form);
                    this.router.navigate(['final-informations/' + this.pageId]);
                } else {
                    if(segmentPizza){
                        this.establishmentService.updateForm(form)
                        this.router.navigate(['establishment/pizza/' + this.pageId])
                    } else {
                        this.establishmentService.updateForm(form)
                        this.router.navigate(['address/' + this.pageId])
                    }
                }
            } else {
                this.anotaToastService.setShowToast({
                    type: 'danger',
                    message: 'Preencha os campos corretamente!'
                });
            }
        } else {
            this.anotaToastService.setShowToast({
                type: 'danger',
                message: 'Preencha os campos corretamente!'
            });
        }
    }

    // segmentChange(){
    //     const otherOption = this.listSegment.find( element => element.option == 'Outros');
    //     if(otherOption.checked){
    //         this.otherSegment = true;
    //         this.myForm.get('otherSegment').enable();
    //         document.getElementById('segment').click();


    //     } else {
    //         this.otherSegment = false;
    //         this.myForm.get('otherSegment').disable();
    //     }

    // }
}