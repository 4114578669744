import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from '@angular/router';
import { MixPanelService } from "../shared/services/mix-panel/mix-panel.service";
import { RegisterService } from "../shared/services/register-service/register.service";

@Component({
    selector: 'app-home',
    templateUrl: 'home.component.html',
    styleUrls: ['home.component.scss']

})
export class HomeComponent implements OnInit {
    public information = false
    public verifyClick = 0
    public innerWidth;
    public pageId;

    constructor(
        private registerService: RegisterService,
        private router: Router,
		private route: ActivatedRoute,
        private mixPanelService: MixPanelService
    ){
        this.pageId = this.route.snapshot.paramMap.get('id');
        this.registerService.pageId = this.pageId;
        this.registerService.home_detection = true;
    }

    ngOnInit(){}

    confirm(){
        this.mixPanelService.sendEvent('click_fe_home_pageview_start')
        this.router.navigate(["operation-hours/" + this.pageId])
    }
}