import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({providedIn: 'root'})
export class AnotaLoaderService {
  showLoader = new BehaviorSubject<boolean>(false);

  setShowLoader(value: boolean) {
    this.showLoader.next(value);
  }

  getShowLoader(): Observable<boolean> {
    return this.showLoader.asObservable();
  }
}