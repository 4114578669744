import { Component, HostListener, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { RegisterForm } from "src/app/shared/interfaces/register-form";
import { RegisterService } from "src/app/shared/services/register-service/register.service";

@Component({
    selector: 'app-error',
    templateUrl: 'error.component.html',
    styleUrls: ['error.component.scss']
})
export class ErrorComponent implements OnInit {
    public pageId;
    public sessionForm;
    public registerForm: RegisterForm = {};
    public innerWidth;
    public errorUrl = false;

    constructor(
        private registerService: RegisterService,
		private route: ActivatedRoute,
    ){
        this.pageId = this.route.snapshot.paramMap.get('id');
        this.registerService.pageId = this.pageId;
        this.sessionForm = this.registerService.getform();
        const token = localStorage.getItem('token')
        this.registerService.directsAdmin();
    }

    @HostListener("window:resize", ["$event"])
    onResize(event) {
        this.innerWidth = window.innerWidth;
    }
    
    ngOnInit(){
        this.route.data.subscribe((res:any)=>{
            this.errorUrl = res.errorUrl;
        })
    }
}