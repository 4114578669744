<div class="flow-home">
    <div class="container">
        <div class="img-container">
            <img src="../../assets/anotaai.png" alt="anotaai">
        </div>
        <div class="title-container">
            <p>Dados do seu estabelecimento</p>
        </div>
        <form [formGroup]="myForm" novalidate>
            <div>
                <anota-input-text 
                size="md"
                label="Telefone do Estabelecimento *"
                mask="cel"
                placeholder="(00)00000-0000"
                formControlName="phone"
                helperText="Certifique-se que esse número possui WhatsApp."
                ></anota-input-text>
                <br>
                <br>
            </div>
        </form>
        <br>
        <div class="input-container">
            <span>Qual(is) o(s) segmento(s) do seu estabelecimento? *</span>
            <p style="color: #9D9D9D; font-size: 12px;">Selecione quantas opções preferir</p>
            <div class="container-checkbox">
                <div *ngFor="let list of listSegment" >
                    <!-- (anotaChange)="changeValue(list.name,$event)"
                    [disabled]="checkNoMarketplace(list.name)" -->
                    <anota-table-column>
                        <anota-badge 
                            [color]="list.checked == true ? 'info' : 'secondary'"
                            (click)="setSeg(list)"
                            [text]="list.option"
                            size="lg"
                        >
                        </anota-badge>
                    </anota-table-column>
                    <!-- <anota-checkbox
                    [(ngModel)]="list.checked"
                    >{{list.option}}
                    </anota-checkbox> -->
                    <br>
                    <!-- <div *ngIf="otherMarketplace.checked && list.option === 'Outro'" >
                        <anota-input-text
                        [(ngModel)]="otherMarketplace.option"
    
                        placeholder="Qual?"
                        ></anota-input-text>
                    </div> -->
                </div>
                <br>
            </div>
        </div>
        <div class="button">
            <anota-button size="lg" type="outline-primary" (click)="returnPage($event)">Anterior</anota-button>
            <anota-button size="lg" (click)="createForm()">Próximo</anota-button>
        </div>
    </div>
</div>
<app-footer class="footer"></app-footer>