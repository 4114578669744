<div class="video-player-container" #videoPlayerContainer>
  <video width="100%" height="100%" #videoPlayer (click)="tooglePlay()" (timeupdate)="onTimeUpdate()" (poster)="thumb">
    <source [src]="url" [type]="type">
  </video>

  <div class="video-controls" *ngIf="!isThumb">
    <div>
      <button class="btn" (click)="tooglePlay()">
        <i class="fa fa-play" aria-hidden="true" *ngIf="!isPlaying"> </i>
        <i class="fa fa-pause" aria-hidden="true" *ngIf="isPlaying"> </i>
      </button>

      <span class="volume-wrapper">
        <div class="input">
          <input type="range" orient="vertical" min="0" max="1" step="0.01" #volumeRanger (input)="toogleVolume(volumeRanger.value)">
        </div>
        <button (click)="toogleMute()">
          <i class="fa fa-volume-up" aria-hidden="true" *ngIf="!isMuted && volume > .5"> </i>
          <i class="fa fa-volume-down" aria-hidden="true" *ngIf="!isMuted && volume < .5 && volume > 0"> </i>
          <i class="fa fa-volume-off" aria-hidden="true" *ngIf="isMuted || volume == 0"> </i>
        </button>
      </span>

      <span class="timer">
        {{currentTime}} - {{videoDuration}}
      </span>
    </div>

    <button class="btn btn-fullscreen" (click)="handleFullscreen()">
      <i class="fa fa-expand" aria-hidden="true"> </i>
    </button>
  </div>
</div>

<img [src]="thumb" class="video_thumb" *ngIf="isThumb">

<div class="presentation-video__layer" *ngIf="isThumb">
  <div class="play-button__circle" (click)="closeThumb()">
    <img src="assets/play-icon.svg" />
  </div>
</div>
