<label class="option-card">
	<div>
		<input
			type="radio"
			[formControl]="control"
			[disabled]="disabled"
			[checked]="checked"
			[value]="value"
		/>
		<span>{{ label }}</span>
	</div>
</label>
