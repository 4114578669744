<div class="flow-home">
    <form [formGroup]="myForm" novalidate >
        <div class="container">
            <div class="img-container">
                <img src="../../assets/anotaai.png" alt="anotaai">
            </div>
            <div class="title-container">
                <p>Dados do seu estabelecimento</p>
            </div>
            <div class="input-container">
                <anota-input-text 
                size="lg"
                label="Qual o nome completo do assinante?*"
                placeholder="Responda aqui"
                formControlName="name"
                ></anota-input-text>
                <br>
                <anota-input-text 
                size="lg"
                label="Numero do CPF ou CNPJ *"
                placeholder="Responda aqui"
                formControlName="cpf_cnpj"
                mask="cpf_cnpj"
                ></anota-input-text>
                <br>
                <anota-input-text 
                size="lg"
                label="Razão Social *"
                placeholder="Responda aqui"
                formControlName="company_name"
                ></anota-input-text>
                <br>
                <anota-input-text 
                size="lg"
                label="Nome Fantasia *"
                placeholder="Responda aqui"
                formControlName="fantasy_name"
                helperText="Nome do seu estabelecimento"
                ></anota-input-text>
                <!-- <br>
                <anota-multi-select
                    size="sm"
                    label="Segmento *"
                    placeholder="Ex.: Lanchonete"
                    formControlName="segment"
                    [options]="listSegment"
                ></anota-multi-select> -->
            </div>
            <br>
            <div class="button">
                <anota-button size="lg" type="outline-primary" (click)="returnPage()">Anterior</anota-button>
                <anota-button size="lg" (click)="createForm()" [disabled]="myForm.invalid">Próximo</anota-button>
            </div>
        </div>
    </form>
</div>
<app-footer class="footer"></app-footer>