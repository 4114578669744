import { HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatRadioModule } from '@angular/material/radio';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DsModule } from '@githubanotaai/design-system';
import { NgxMaskModule } from 'ngx-mask';
import { AppRoutingModule } from '../app-routing.module';
import { SharedModule } from '../shared/shared.module';

import { EstablishmentPart1Component } from './establishment-part-1/establishment-part-1.component';
import { EstablishmentPart2Component } from './establishment-part-2/establishment-part-2.component';
import { EstablishmentPart3Component } from './establishment-part-3/establishment-part-3.component';
import { EstablishmentComponent } from './establishment.component';
import { MenuComponent } from './menu/menu.component';
import { PizzaComponent } from './pizza/pizza.component';
import { PizzaPart3Component } from './pizza-part-3/pizza-part-3.component';

@NgModule({
    declarations: [
        EstablishmentComponent,
        EstablishmentPart1Component,
        EstablishmentPart2Component,
        EstablishmentPart3Component,
        MenuComponent,
        PizzaComponent,
        PizzaPart3Component,
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        DsModule,
        FlexLayoutModule,
        HttpClientModule,
        FormsModule,
        SharedModule,
        NgxMaskModule.forRoot(),
        BrowserAnimationsModule, 
        MatRadioModule,
        MatCardModule,
        MatIconModule
    ],
    exports:[
        EstablishmentComponent,
        EstablishmentPart1Component,
        EstablishmentPart2Component,
        EstablishmentPart3Component,
        MenuComponent,
        PizzaComponent,
        PizzaPart3Component,
    ],
    providers: [],
    bootstrap: [EstablishmentModule]
})
export class EstablishmentModule { }