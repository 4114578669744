import { HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatRadioModule } from '@angular/material/radio';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DsModule } from '@githubanotaai/design-system';
import { NgxMaskModule } from 'ngx-mask';
import { AppRoutingModule } from '../app-routing.module';
import { SharedModule } from '../shared/shared.module';
import { PaymentInformationsComponent } from './payment-informations.component';


@NgModule({
    declarations: [
        PaymentInformationsComponent,
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        DsModule,
        FlexLayoutModule,
        HttpClientModule,
        FormsModule,
        SharedModule,
        NgxMaskModule.forRoot(),
        BrowserAnimationsModule, 
        MatRadioModule,
        MatCardModule,
        MatIconModule
    ],
    exports:[
        PaymentInformationsComponent,
    ],
    providers: [],
    bootstrap: [PaymentInformationsModule]
})
export class PaymentInformationsModule { }