<div>
  <footer class="main-footer">
    <div class="container">
      <span>© Copyright 2021. Todos os direitos reservados.</span>
      <nav>
        <a href="https://anota.ai/termos/Termos_de_Uso.pdf" target="_blank" rel="noopener noreferrer">Termos de Uso</a>
        <a href="https://anota.ai/politicas/Politica_de_Privacidade.pdf" target="_blank" rel="noopener noreferrer">Politica de Privacidade</a>
      </nav>
    </div>
  </footer>
</div>
