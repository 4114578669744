import { style } from '@angular/animations';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RegisterService } from '../../services/register-service/register.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  public pageId;

  constructor(
      private registerService: RegisterService,
		  private route: ActivatedRoute,
  ) { 
    this.pageId = this.route.snapshot.paramMap.get('id');
    this.registerService.pageId = this.pageId;
  }

  ngOnInit(): void {
  }

}
