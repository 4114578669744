import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { Observable, Subscription } from "rxjs";
import { RegisterForm } from "src/app/shared/interfaces/register-form";
import { RegisterService } from "src/app/shared/services/register-service/register.service";
import { AnotaLoaderService } from "../shared/services/ds-services/anota-loader.service";
import { AnotaToastService } from "../shared/services/ds-services/anota-toast.service";
import { MixPanelService } from "../shared/services/mix-panel/mix-panel.service";
import { SessionStorageService } from "../shared/services/session-storage/session-storage.service";

@Component({
    selector: 'app-verify-informations',
    templateUrl: 'verify-informations.component.html',
    styleUrls: ['verify-informations.component.scss']
})
export class VerifyInformationsComponent implements OnInit {
    public myForm: FormGroup;
    public pageId;
    public sessionForm;
    public registerForm: RegisterForm = {};
    public verifyInformations: boolean;
    public loading: boolean = false

    constructor(
        private registerService: RegisterService,
        private sessionStorageService: SessionStorageService,
        private formBuilder: FormBuilder,
        private anotaToastService: AnotaToastService,
        private anotaLoaderService: AnotaLoaderService,
        private router: Router,
		private route: ActivatedRoute,
        private mixPanelService: MixPanelService
    ){
        this.pageId = this.route.snapshot.paramMap.get('id');
        this.registerService.pageId = this.pageId;
        this.sessionForm = this.registerService.getform();
    }
    
    ngOnInit(){
        this.initializeForm();
    }

    initializeForm(){
        this.myForm = this.formBuilder.group({
            verify_informations: ['', [Validators.required]],
        })
    }

    returnPage(){
        window.history.back()
    }

    confirm(){
        this.loading = true;
        this.anotaLoaderService.setShowLoader(this.loading);
        this.sessionForm.filled_onboarding_register = true
        
        try{
            const wichObject = {
            'Por Bairro': 'attendance_district',
            'Por Raio': 'attendance_radius',
            'Mesmo valor para qualquer localidade': 'attendance_city_value'
        }

        let chosenObject;


        if(this.sessionForm.attendance_model.label == 'Mesmo valor para qualquer localidade'){
            chosenObject = {name: 'Mesmo valor para qualquer localidade', value: this.sessionForm.attendance_city_value}
            this.sessionForm.regions = [chosenObject]
        }else{
            chosenObject = this.sessionForm[wichObject[this.sessionForm.attendance_model.label]]
            this.sessionForm.regions = chosenObject.options
    
        }

        }   catch(err){
            console.log(err)
        }
        
        
        
        if(this.myForm.valid){
            this.registerService.setForm(this.sessionForm).subscribe((res: any)=>{
                if(res.success){
                    this.loading = false;
                    this.anotaLoaderService.setShowLoader(this.loading);
                    this.sessionStorageService.clearForm()
                    this.router.navigate(['finalize/' + this.pageId]);
                } else{
                    this.loading = false;
                    this.anotaLoaderService.setShowLoader(this.loading);
                    this.anotaToastService.setShowToast({
                        type: 'danger',
                        message: 'Opps, ocorreu algum erro!'
                    });
                }
            },
            (err) =>{
                this.loading = false;
                this.anotaLoaderService.setShowLoader(this.loading);
                console.log("ERRO", err)
                this.anotaToastService.setShowToast({
                    type: 'danger',
                    message: 'Opps! Ocorreu algum erro'
                });
            })
        }
        
    }
}