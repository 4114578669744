<div class="steps-container">
    <div class="progress-bg">
        <div class="bar" style="--progress-bar: {{step}}"></div>
    </div>
    <div class="step" [ngClass]="{'active': step == 0, 'checked': step > 0}">
        <div class="icon">
        <anota-icon iconName="clock"></anota-icon>
        </div>
    <!-- <span class="text">Dados</span> -->
    </div>
    <div class="step" [ngClass]="{'active': step == 1, 'checked': step > 1}">
        <div class="icon">
        <anota-icon iconName="deliverymen" iconStroke="1.3"></anota-icon>
        </div>
        <!-- <span class="text">Responsável</span> -->
    </div>
    <div class="step" [ngClass]="{'active': step >= 2}" [ngClass]="{'active': step == 2, 'checked': step > 2}">
        <div class="icon">
        <anota-icon iconName="map-pin" iconStroke="1.3"></anota-icon>
        </div>
        <!-- <span class="text">Dados</span> -->
    </div>
    <div class="step" [ngClass]="{'active': step === 3}" [ngClass]="{'active': step == 3, 'checked': step > 3}">
        <div class="icon">
        <anota-icon iconName="dollar-sign" iconStroke="1.3"></anota-icon>
        </div>
        <!-- <span class="text">Endereço</span> -->
    </div>
    <div class="step" [ngClass]="{'active': step === 4}" [ngClass]="{'active': step == 4, 'checked': step > 4}">
        <div class="icon">
            <anota-icon iconName="image" iconStroke="1.3"></anota-icon>
        </div>
        <!-- <span class="text">Teste</span> -->
    </div>
</div>
