import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-utm-redirect',
  templateUrl: './utm-redirect.component.html',
  styleUrls: ['./utm-redirect.component.scss'],
})
export class UtmRedirectComponent implements OnInit {
  constructor(private route: ActivatedRoute, private router: Router) {}

  ngOnInit(): void {
    const utmSource = this.route.snapshot.paramMap.get('utm_source');
    const utmContent = this.route.snapshot.paramMap.get('utm_content');
    const id = this.route.snapshot.paramMap.get('id');

    this.router.navigate([`home/${id}`], {
      queryParams: { utm_source: utmSource, utm_content: utmContent },
    });
  }
}
