
<div class="flow-home">
    <div class="container">
        <div class="img-container">
            <img src="../../assets/logo.svg" style="height: 50px;" alt="anotaai">
        </div>
        <div >
            <div class="title-container">
                <p>Olá, preencha o formulário para concluir seu cadastro na Anota AI</p>
            </div>
            <div class="subtitle-container">
                <p>As respostas são salvas automaticamente. Continue o preenchimento a qualquer momento sem perder as informações.</p>
            </div>
            <div class="warning-container">
                <p>* Tempo estimado de preenchimento: 5 minutos</p>
            </div>
        </div>
        <div class="title-link">
            <a href="https://anota.ai/termos/Termos_de_Uso.pdf" target="_blank"><p>Termos de uso</p></a>
            <a href="https://anota.ai/politicas/Politica_de_Privacidade.pdf" target="_blank"><p>Políticas de privacidade</p></a>
        </div>
    </div>
    <div>
        <anota-footer [noSidebar]="true" [noMenuFooter]="true" class="footer">
            <div class="button-container">
                <anota-button size="lg" (click)="confirm()"> Começar </anota-button>
            </div>
        </anota-footer>
    </div>
</div>
<!-- <anota-footer></anota-footer>  -->
<!-- -->