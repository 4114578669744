<div class="flow-home">
    <div class="container">
        <div class="img-container">
            <img src="../../assets/anotaai.png" alt="anotaai">
        </div>
        <div class="title-container">
            <p>Dados do cardápio de pizza</p>
        </div>
        <div class="subtitle-container">
        </div>
        <form [formGroup]="myForm" novalidate>
            <div class="input-container">
                <div>
                    <span>Como é calculado o preço das pizzas com mais de um sabor?</span>
                    <br>
                    <br>
                    <anota-radio-group class="pizza-select" formControlName="pizza_price_calculation">
                        <anota-radio value="Média">Média</anota-radio>
                        <anota-radio value="Maior Valor">Maior Valor</anota-radio>
                    </anota-radio-group>
                </div>
                <br>
                <br>
                <div>
                    <span>Pode misturar sabores salgados e doces?</span>
                    <br>
                    <br>
                    <anota-radio-group class="pizza-select" formControlName="mix_flavors_pizza">
                        <anota-radio value="Sim" >Sim</anota-radio>
                        <anota-radio value="Não" >Não</anota-radio>
                    </anota-radio-group>
                </div>
                <br>
                <br>
                <div>
                    <span>Os adicionais são para pizzas inteiras ou por sabor?</span>
                    <br>
                    <br>
                    <anota-radio-group class="pizza-select" formControlName="add_pizza">
                        <anota-radio value="Inteiras">Inteiras</anota-radio>
                        <anota-radio value="Sabor">Sabor</anota-radio>
                    </anota-radio-group>
                </div>
            </div>
        </form>
        <div class="button">
            <anota-button size="lg" type="outline-primary" (click)="returnPage()">Anterior</anota-button>
            <anota-button size="lg" (click)="createForm()" [disabled]="myForm.invalid">Próximo</anota-button>
        </div>
    </div>
</div>
<app-footer class="footer"></app-footer>