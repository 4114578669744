<div class="flow-home">
    <div class="container">
        <div class="img-container">
            <img src="../../assets/logo.svg" style="height: 50px;" alt="anotaai">
        </div>

        <app-progress-bar [step]="step"></app-progress-bar>
        <br>

        <div class="title-container">
            <p>Entrega ou retirada de pedidos</p>
        </div>
        <div class="subtitle-container">
            <p>Selecione as opções que melhor representam as modalidades de entrega/retirada do seu estabelecimento.</p>
        </div>
        <form [formGroup]="myForm" novalidate>
            <div class="input-container">
                <anota-checkbox 
                    helperText="O pedido chega até o cliente por um entregador." 
                    formControlName="delivery"
                    >Delivery
                </anota-checkbox>
                <br>
                <br>
                <anota-checkbox 
                    helperText="O cliente vai até o estabelecimento e retira o pedido." 
                    formControlName="take_local"
                    >Retirada no estabelecimento
                </anota-checkbox>
                <br>
                <br>
                <anota-checkbox 
                    id="check"
                    helperText="O cliente vai até o estabelecimento e consome no local. " 
                    formControlName="consume_local"
                    (click)="localConsuption()"
                    >Consumo no local
                </anota-checkbox>
                <br>
                <div *ngIf="myForm.controls.consume_local.value" style="width: auto;">
                    <p style="color: #5A5A5A; font-size: 14px;">Preencha os campos abaixo para ativar o modo garçom:</p>
                    <div >
                    <anota-input-number [messageError]="messageError" label="Mesas do salão *" formControlName="quantity_tables"></anota-input-number>
                        <br>
                        <br *ngIf="messageError">
                        <anota-input-number label="% de Taxa de serviço" formControlName="waiter_tip_percent"></anota-input-number>
                    </div>
                    <br>
                    <p id="consuption" style="color: #5A5A5A; font-size: 14px;">Ao clicar em próximo, o sistema irá cadastrar as mesas e a taxa de serviço. Você poderá alterar
                    todas essas informações no sistema na seção de Mesas e Garçons.</p>
                </div>
            </div>
        </form>
    </div>
    <anota-footer [noSidebar]="true" [noMenuFooter]="true" class="footer">
        <div class="button">
            <anota-button size="lg" type="outline-primary" (click)="returnPage()">Anterior</anota-button>
            <anota-button size="lg" (click)="createForm()" 
            [disabled]="
            myForm.controls.consume_local.value == '' &&
            myForm.controls.take_local.value == '' &&
            myForm.controls.delivery.value == ''
            "
            >Próximo</anota-button>
        </div>
    </anota-footer>
</div>
<!-- <app-footer class="footer"></app-footer> -->