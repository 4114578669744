import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { EstablishmentComponent } from './establishment/establishment.component';
import { FinalizeComponent } from './finalize/finalize.component';
import { HomeComponent } from './home/home.component';
import { FinalInformationsComponent } from './final-informations/final-informations.component';
import { EstablishmentPart1Component } from './establishment/establishment-part-1/establishment-part-1.component';
import { EstablishmentPart2Component } from './establishment/establishment-part-2/establishment-part-2.component';
import { EstablishmentPart3Component } from './establishment/establishment-part-3/establishment-part-3.component';
import { MenuComponent } from './establishment/menu/menu.component';
import { PizzaComponent } from './establishment/pizza/pizza.component';
import { PizzaPart3Component } from './establishment/pizza-part-3/pizza-part-3.component';
import { AddressComponent } from './address/address.component';
import { OperationHoursComponent } from './operation-hours/operation-hours.component';
import { DeliveryInformationsComponent } from './delivery-informations/delivery-informations.component';
import { DeliveryInformations2Component } from './delivery-informations/delivery-informations-2.component';
import { AttendanceLocationComponent } from './attendance-location/attendance-location.component';
import { PaymentInformationsComponent } from './payment-informations/payment-informations.component';
import { VerifyInformationsComponent } from './verify-informations/verify-informations.component';
import { ErrorComponent } from './error/error.component';
import { UtmRedirectComponent } from './utm-redirect/utm-redirect.component';
import { InstagramComponent } from './instagram/instagram.component';

const routes: Routes = [
  {
    path: 'home/:id',
    component: HomeComponent,
    data: {},
  },
  {
    path: 'establishment',
    component: EstablishmentComponent,
    children: [
      // {
      //   path: 'establishment-part-1/:id',
      //   component: EstablishmentPart1Component,
      //   data: {},
      // },
      // {
      //   path: 'establishment-part-2/:id',
      //   component: EstablishmentPart2Component,
      //   data: {},
      // },
      // {
      //   path: 'establishment-part-3/:id',
      //   component: EstablishmentPart3Component,
      //   data: {},
      // },
      {
        path: 'menu/:id',
        component: MenuComponent,
        data: {},
      },
      // {
      //   path: 'pizza/:id',
      //   component: PizzaComponent,
      //   data: {},
      // },
      // {
      //   path: 'pizza-part-3/:id',
      //   component: PizzaPart3Component,
      //   data: {},
      // },
    ],
  },
  // {
  //   path: 'address/:id',
  //   component: AddressComponent,
  //   data: {},
  // },
  {
    path: 'operation-hours/:id',
    component: OperationHoursComponent,
    data: {},
  },
  {
    path: 'delivery-informations/:id',
    component: DeliveryInformationsComponent,
    data: {},
  },
  // {
  //   path: 'delivery-informations-2/:id',
  //   component: DeliveryInformations2Component,
  //   data: {},
  // },
  {
    path: 'attendance-location/:id',
    component: AttendanceLocationComponent,
    data: {},
  },
  {
    path: 'payment-informations/:id',
    component: PaymentInformationsComponent,
    data: {},
  },
  {
    path: 'final-informations/:id',
    component: FinalInformationsComponent,
    data: {},
  },
  {
    path: 'verify-informations/:id',
    component: VerifyInformationsComponent,
    data: {},
  },
  {
    path: 'finalize/:id',
    component: FinalizeComponent,
    data: {},
  },
  {
    path: 'instagram/:id',
    component: InstagramComponent,
    data: {},
  },
  {
    path: 'utm-redirect/:utm_source/:utm_content/:id',
    component: UtmRedirectComponent,
  },
  {
    path: 'error',
    component: ErrorComponent,
  },
  {
    path: '**',
    component: ErrorComponent,
    data: {
      errorUrl: true,
    },
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
