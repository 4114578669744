import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from '@angular/router';
import { MixPanelService } from "../shared/services/mix-panel/mix-panel.service";
import { RegisterService } from "../shared/services/register-service/register.service";
import { AbstractControl, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { RegisterForm } from "../shared/interfaces/register-form";
import { SessionStorageService } from "../shared/services/session-storage/session-storage.service";
import { AnotaToastService } from "../shared/services/ds-services/anota-toast.service";
import { AnotaLoaderService } from "../shared/services/ds-services/anota-loader.service";

@Component({
    selector: 'app-instagram',
    templateUrl: 'instagram.component.html',
    styleUrls: ['instagram.component.scss']

})
export class InstagramComponent implements OnInit {
    public myForm: FormGroup;
    public pageId;
    public sessionForm;
    public registerForm: RegisterForm = {};
    public messageError: string = '';
    public linkVerify = false;
    public loading = false;
    public innerWidth;
    public form: any = {};
    public step: number = 5;
    

    constructor(
        private registerService: RegisterService,
        private sessionStorageService: SessionStorageService,
        private anotaToastService: AnotaToastService,
        private mixPanelService: MixPanelService,
        private anotaLoaderService: AnotaLoaderService,
        private formBuilder: FormBuilder,
        private router: Router,
		private route: ActivatedRoute,
    ){
        this.pageId = this.route.snapshot.paramMap.get('id');
        this.registerService.pageId = this.pageId;
        this.sessionForm = this.registerService.getform();
    }

    ngOnInit(){
        this.initializeForm();
    }

    initializeForm(){
        this.myForm = this.formBuilder.group({
            page_instalink: [this.sessionForm?.page_instalink || '', [ Validators.required, InstagramComponent.validateInsta()]]
        })
    }

    static validateInsta(){
        return (control : AbstractControl): Validators=>{
            const instagram = control.value;
            if(instagram.includes('@')) {
                return null
            }
            else {
                return {instagramInvalid: true}
            }

        }
    }

    verifyLink(event){
        this.mixPanelService.sendEvent('click_fe_instagram_inform_later')
        event?.returnValue == true ? this.linkVerify = true : this.linkVerify = false;
        this.anotaToastService.setShowToast({
            type: 'success',
            message: 'Ok! Perguntaremos depois'
        });
    }

    returnPage(){
        window.history.back()
    }

    createForm(){
        this.loading = true;
        this.anotaLoaderService.setShowLoader(this.loading);
        this.mixPanelService.sendEvent('click_fe_instagram');

        this.form = {
            page_instalink: this.myForm.value?.page_instalink,
            filled_onboarding_register: true
        }

        try{
            const wichObject = {
            'Por Bairro': 'attendance_district',
            'Por Raio': 'attendance_radius',
            'Mesmo valor para qualquer localidade': 'attendance_city_value'
        }

        let chosenObject;


        if(this.sessionForm.attendance_model == 'Mesmo valor para qualquer localidade'){
            chosenObject = {name: 'Mesmo valor para qualquer localidade', value: this.sessionForm.attendance_city_value}
            this.sessionForm.regions = [chosenObject]
        }else{
            chosenObject = this.sessionForm[wichObject[this.sessionForm.attendance_model]]
            this.sessionForm.regions = chosenObject.options
    
        }

        }   catch(err){
            console.log(err)
            this.loading = false;
            this.anotaLoaderService.setShowLoader(this.loading);
        }

        this.sessionStorageService.updateForm(this.form);
        this.registerService.setForm(this.sessionForm).subscribe((res: any)=>{
            if(res.success){
                this.loading = false;
                this.anotaLoaderService.setShowLoader(this.loading);
                this.mixPanelService.sendEvent('callback_response_fe_finalize', {success: true})
                this.router.navigate(['finalize/' + this.pageId]);
            }
        },
        (err) =>{
            this.loading = false;
            this.anotaLoaderService.setShowLoader(this.loading);
            this.mixPanelService.sendEvent('callback_response_fe_finalize', {success: false, error_message: err.error.message})
            console.log("ERRO", err)
        })
    }
}