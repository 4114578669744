import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { RegisterForm } from "src/app/shared/interfaces/register-form";
import { AnotaLoaderService } from "src/app/shared/services/ds-services/anota-loader.service";
import { AnotaToastService } from "src/app/shared/services/ds-services/anota-toast.service";
import { RegisterService } from "src/app/shared/services/register-service/register.service";
import { SessionStorageService } from "src/app/shared/services/session-storage/session-storage.service";
import { MixPanelService } from "src/app/shared/services/mix-panel/mix-panel.service";
import { HttpHeaders } from "@angular/common/http";


@Component({
    selector: 'app-menu',
    templateUrl: 'menu.component.html',
    styleUrls: ['menu.component.scss']
})
export class MenuComponent implements OnInit {
    public myForm: FormGroup;
    public pageId;
    public sessionForm;
    public registerForm: RegisterForm = {};
    public images: Array <any> = [{}];
    public deleteImages: Array <any> = [{}];
    public documents: Array <any> = [{}];
    public deleteDocuments: Array <any> = [{}];
    public messageError: string = '';
    public base64: Blob;
    public let: any;
    public loading: boolean;
    public form: any = {}
    public linkVerify = false;
    public innerWidth;
    public step: number = 4;
    // public invalidMenuLink: boolean = true;
    
    @ViewChild('myUploadButton')
    myUploadButton: ElementRef;
    
    constructor(
        private registerService: RegisterService,
        private sessionStorageService: SessionStorageService,
        private anotaToastService: AnotaToastService,
        private mixPanelService: MixPanelService,
        private anotaLoaderService: AnotaLoaderService,
        private formBuilder: FormBuilder,
        private router: Router,
		private route: ActivatedRoute,
    ){
        this.pageId = this.route.snapshot.paramMap.get('id');
        this.sessionStorageService.pageId = this.pageId;
        this.sessionForm = this.registerService.getform();
        this.innerWidth = window.innerWidth;
        
        if(!this.sessionForm?.menu_documents){
            this.documents = [{}]; 
        } else if(this.sessionForm?.menu_documents.length == 0){
            this.documents = [{}]; 
        } else {
            this.documents = this.sessionForm?.menu_documents
        }
    }
    
    ngOnInit(){
        this.initializeForm();
    }

    initializeForm(){
        this.myForm = this.formBuilder.group({
            menu_link: [this.sessionForm?.menu_link || '']
        })
    }

    changeDocsMenu(event: any, index) {
        if(event.includes("data:")){

            let base64 = event.split(',')[1];
            let decoded = atob(base64);
            let size = decoded.length/1024;
            let form_data = new FormData();
            // const headers = {
            //     'Content-Type': 'multipart/form-data'
            // }

            let format = event.split(';')[0].split('/')[1];

            let multipart = this.convertBase64ToFile(event, `Menu-${index+1}`)
            form_data.append('url', multipart)

            this.loading = true;
            this.anotaLoaderService.setShowLoader(this.loading);
            this.registerService.addMenu(form_data).subscribe((res:any)=>{
                this.documents[index].url = res.data
                this.deleteDocuments[index].url = res.data
                this.mixPanelService.sendEvent('callback_fe_menu_image', {
                    success: true,
                    type: format,
                    size: size
                })
                this.loading = false;
                this.anotaLoaderService.setShowLoader(this.loading);
            }, (err) =>{
                this.documents[index] = {};
                this.loading = false;
                this.mixPanelService.sendEvent('callback_fe_menu_image', {
                    success: false,
                    type: format,
                    size: size,
                    error_message: err.message
                })
                this.anotaLoaderService.setShowLoader(this.loading);
                this.anotaToastService.setShowToast({
                    type: 'danger',
                    message: err.message
                });
            })
            
        } else if (!event){
            let deleteUrl = ''
            const arrayFilter = this.deleteDocuments.filter(element => {
                return this.documents.every((res)=>{
                    return res.url != element.url
                })
            });

            arrayFilter.forEach((element)=>{
                deleteUrl = element.url
            })
            
            const body = {
                url: deleteUrl
            }
            
            this.loading = true;
            this.anotaLoaderService.setShowLoader(this.loading);
            this.registerService.deleteMenu(body).subscribe((res:any)=>{
                this.loading = false;
                this.anotaLoaderService.setShowLoader(this.loading);
                this.anotaToastService.setShowToast({
                    type: 'succes',
                    message: res.message
                });
                
            }, (err) =>{
                this.loading = false;
                this.anotaLoaderService.setShowLoader(this.loading);
            })
        }
    }

    //convert base64 to blob
    b64toBlob(base64Data, type) {
        let byteCharacters = atob(base64Data);
        let byteArrays = [];
        for (let i = 0; i < byteCharacters.length; i++) {
            byteArrays.push(byteCharacters.charCodeAt(i));
        }
        let byteArray = new Uint8Array(byteArrays);
        return new Blob([byteArray], {type});
    }

  //convert blob to multipart
    convertBase64ToFile(base64Docs, filename) {
        let baseType : any = base64Docs.split(';')[0]
        baseType = baseType.split('data:')[1];
        let base64Data: any = base64Docs.split(',')[1];
        let blob = this.b64toBlob(base64Data, baseType);
        let file = new File([blob], filename, { type: blob.type });
    
        return file;
    }

    addDocsMenu(index){
        if(index < 19){
            const verify = this.documents.find(element => element.url);
            if(verify){
                this.documents[index + 1] = {}
                this.deleteDocuments[index + 1] = {}

                if(this.innerWidth <= 768){
                    let id = document.getElementById('input-image').id = `input-image-${index+1}`;
                    document.getElementById(id).scrollIntoView({behavior:'smooth'});
                }
            }
        } else {
            this.anotaToastService.setShowToast({
                type: 'danger',
                message: 'O limite de máximo para envio é de 20 arquivos!'
            });
        }
    }

    verifyLink(event){
        this.mixPanelService.sendEvent('click_fe_menu_link')
        event?.returnValue == true ? this.linkVerify = true : this.linkVerify = false;
    }

    returnPage(){
        window.history.back()
    }

    createForm(){
        this.mixPanelService.sendEvent('click_fe_menu_image_import_start')

        const element = this.documents.filter(element => element.url)
        const verify = element.find(element => element.url == '');
        let regexp =  /^(?:(?:https?):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;

        if(Object.keys(this.myForm.controls).includes('menu_documents')){
            this.myForm.controls.menu_documents.setValue(element);
        } else{
            this.myForm.addControl('menu_documents', new FormControl(element));
        }
        
        this.form = {
            menu_link: '',
            menu_documents: this.myForm.value?.menu_documents || [],
        }

        if(regexp.test(this.myForm.value?.menu_link) || this.myForm.value?.menu_link==''){
            this.form.menu_link = this.myForm.value?.menu_link;
            if(this.form.menu_documents.length!=0 || this.form.menu_link!=''){
                this.sessionStorageService.updateForm(this.form);
                this.router.navigate(['instagram/' + this.pageId]);
            } else{
                this.anotaToastService.setShowToast({
                    type: 'danger',
                    message: 'Envie um arquivo ou forneça o link do seu cardápio!'
                }); 
    
                this.form = {};
            }
        } else {
            this.anotaToastService.setShowToast({
                type: 'danger',
                message: 'Formato inválido, cheque se o link enviado está correto!'
            }); 
        }
    }
}