import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { RegisterForm } from "src/app/shared/interfaces/register-form";
import * as cep from 'cep-promise';
import { RegisterService } from "../shared/services/register-service/register.service";
import { AnotaLoaderService } from "../shared/services/ds-services/anota-loader.service";
import { AnotaToastService } from "../shared/services/ds-services/anota-toast.service";
import { SessionStorageService } from "../shared/services/session-storage/session-storage.service";


@Component({
    selector: 'app-addres-1',
    templateUrl: 'address.component.html',
    styleUrls: ['address.component.scss']
})
export class AddressComponent implements OnInit {
    public myForm: FormGroup;
    public pageId;
    public cep: any = cep
    public sessionForm;
    public registerForm: RegisterForm = {};
    public loading: boolean = false;

    constructor(
        private registerService: RegisterService,
        private sessionStorageService: SessionStorageService,
        private anotaLoaderService: AnotaLoaderService,
        private anotaToastService: AnotaToastService,
        private formBuilder: FormBuilder,
        private router: Router,
		private route: ActivatedRoute,
    ){
        this.pageId = this.route.snapshot.paramMap.get('id');
        this.registerService.pageId = this.pageId;
        this.sessionForm = this.registerService.getform();
    }
    
    ngOnInit(){
        this.initializeForm();
    }
    
    initializeForm(){

        this.myForm = this.formBuilder.group({
            cep: [this.sessionForm.address?.postal_code || '', [ Validators.required]],
            city: [this.sessionForm.address?.city || '', [Validators.required]],
            complement: [this.sessionForm.address?.complement || ''],
            country: [this.sessionForm.address?.country || ''],
            location: [this.sessionForm.address?.location || []],
            reference: [this.sessionForm.address?.reference || ''],
            district: [this.sessionForm.address?.district || '', [Validators.required]],
            number: [this.sessionForm.address?.number || '', [
                Validators.required,
                Validators.maxLength(10)
            ]],
            state: [this.sessionForm.address?.state || '', [Validators.required]],
            street: [this.sessionForm.address?.street || '', [Validators.required]], 
        })

    }

    getLatLong() {
        return new Promise((resolve) => {
            let document = this.myForm.value;
            document.location = this.myForm.value?.location
            document.city = this.myForm.value?.city;
            document.state = this.myForm.value?.state;
    
            const addressFormated = document.street + ", " + document.number + " - " + document.district +
            ", " + document.city + " - " + document.state + ", " + document.cep + ", Brasil";
            const geocoder = new google.maps.Geocoder;
            geocoder.geocode({ 'address': addressFormated }, function (results, status) {
                if (status === google.maps.GeocoderStatus.OK) {
                    if (results[0]) {
                        let coordinates = [];
                        coordinates[0] = results[0].geometry.location.lng(),
                        coordinates[1] = results[0].geometry.location.lat()
                        document.location = {coordinates: coordinates}
                        resolve(document);
                    }
                }
            });
        })
    }

    debounce() {
        if (this.myForm.get('cep').value.length > 7) {
            this.loading = true;
            this.anotaLoaderService.setShowLoader(this.loading);
            this.cep(this.myForm.get('cep').value)
            .then((res) => {
                this.myForm.controls.city.setValue(res.city);
                this.myForm.controls.state.setValue(res.state);
                this.myForm.controls.street.setValue(res.street);
                this.myForm.controls.district.setValue(res.neighborhood);
                this.loading = false;
                this.anotaLoaderService.setShowLoader(this.loading);
            }, (err)=>{
                console.log(err)
                this.loading = false;
                this.anotaLoaderService.setShowLoader(this.loading);
                this.anotaToastService.setShowToast({
                    type: 'danger',
                    message: 'CEP não encontrado!'
                });
            })
        }
    }

    returnPage(){
        window.history.back()
    }
    
    createForm(){
        let form;
        if(this.myForm.valid){
            delete this.sessionForm.address?.location;
            this.getLatLong().then((res:any)=>{
                let cep = res.cep
                if (cep.length === 8) {
                    cep = res.cep.slice(0, 2) + '.' + res.cep.slice(2, 5) + '-' + res.cep.slice(5);
                }

                form = {
                    address: {
                        city: res.city,
                        complement: res.complement,
                        district: res.district,
                        number: res.number,
                        postal_code: cep,
                        state: res.state,
                        street: res.street,
                        country: res.country,
                        location: res.location,
                        reference: res.reference,
                    }
                }

                
                if(this.sessionForm?.final_informations){
                    this.sessionStorageService.updateForm(form);
                    this.router.navigate(['final-informations/' + this.pageId]);
                } else {
                    this.sessionStorageService.updateForm(form);
                    this.router.navigate(['operation-hours/' + this.pageId])
                }
            })
            
            
        }
    }

}