import { Component, HostListener, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from '@angular/router';
import { MixPanelService } from "../shared/services/mix-panel/mix-panel.service";
import { RegisterService } from "../shared/services/register-service/register.service";
import { AnotaToastService } from "../shared/services/ds-services/anota-toast.service";
import { SessionStorageService } from "../shared/services/session-storage/session-storage.service";

@Component({
    selector: 'app-finalize',
    templateUrl: 'finalize.component.html',
    styleUrls: ['finalize.component.scss']

})
export class FinalizeComponent implements OnInit {
    public innerWidth;
    public pageId;
    public step = 5;
    public sessionForm;

    constructor(
        private registerService: RegisterService,
        private router: Router,
		private route: ActivatedRoute,
        private sessionStorageService: SessionStorageService,
        private anotaToastService: AnotaToastService,
        private mixPanelService: MixPanelService,
    ){
            this.innerWidth = window.innerWidth;
            this.pageId = this.route.snapshot.paramMap.get('id');
            this.registerService.pageId = this.pageId;
            this.sessionStorageService.clearForm();
            this.anotaToastService.setShowToast({
                type: 'success',
                message: 'Concluido com sucesso!'
            });
    }

    @HostListener("window:resize", ["$event"])
    onResize(event) {
        this.innerWidth = window.innerWidth;
    }

    ngOnInit(){}

    direct(){
        this.mixPanelService.sendEvent('click_fe_success_explore')
        this.registerService.directsAdmin();
    }
}