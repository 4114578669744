<header class="main-header">
  <div class="container">
    <img class="img-menu" src="../../../../assets/anotaai.png" alt="Logo" />

    <div class="tab-container">
      <div class="tab-menu">
        <a id="you" class="tab-link" routerLink="/you/{{pageId}}" routerLinkActive="active">Você</a>
        <a id="establishment" class="tab-link" routerLink="/establishment/{{pageId}}" routerLinkActive="active">Estabelecimento</a>
        <a id="product" class="tab-link" routerLink="/product/{{pageId}}" routerLinkActive="active">Produto</a>
      </div>
    </div>
    
  </div>
</header>
<br>
<br>
