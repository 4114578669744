import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { RegisterForm } from "src/app/shared/interfaces/register-form";
import { RegisterService } from "../shared/services/register-service/register.service";


@Component({
    selector: 'app-final-informations',
    templateUrl: 'final-informations.component.html',
    styleUrls: ['final-informations.component.scss']
})
export class FinalInformationsComponent implements OnInit {
    public myForm: FormGroup;
    public pageId;
    public sessionForm;
    public registerForm: RegisterForm = {};
    public segmentPizza: any;
    public cardList: any;
    public documents: any;

    constructor(
        private registerService: RegisterService,
        private formBuilder: FormBuilder,
        private router: Router,
		private route: ActivatedRoute,
    ){
        this.pageId = this.route.snapshot.paramMap.get('id');
        this.registerService.pageId = this.pageId;
        this.sessionForm = this.registerService.getform();
    }
    
    ngOnInit(){
        this.segmentPizza = this.sessionForm?.segment?.find( element => element.option =='Pizzaria');
        this.cardList = this.sessionForm?.payment_method?.find(element => element.name == 'Cartão');
        this.sessionForm.final_informations = true;
        this.registerService.updateForm(this.sessionForm);
    }

    returnPage(){
        window.history.back()
    }

    saveForm(){
        this.router.navigate(['verify-informations/' + this.pageId])
    }
}