import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { RegisterForm } from "src/app/shared/interfaces/register-form";
import { RegisterService } from "src/app/shared/services/register-service/register.service";
import { EstablishmentService } from "../establishment.service";


@Component({
    selector: 'app-pizza-part-3',
    templateUrl: 'pizza-part-3.component.html',
    styleUrls: ['pizza-part-3.component.scss']
})
export class PizzaPart3Component implements OnInit {
    public myForm: FormGroup;
    public pageId;
    public sessionForm;
    public registerForm: RegisterForm = {};

    constructor(
        private registerService: RegisterService,
        private establishmentService: EstablishmentService,
        private formBuilder: FormBuilder,
        private router: Router,
		private route: ActivatedRoute,
    ){
        this.pageId = this.route.snapshot.paramMap.get('id');
        this.establishmentService.pageId = this.pageId;
        this.sessionForm = this.registerService.getform();
    }
    
    ngOnInit(){
        this.initializeForm();
    }

    initializeForm(){
        this.myForm = this.formBuilder.group({
            pizza_price_calculation: [this.sessionForm?.pizza_price_calculation || '', [Validators.required]],
            mix_flavors_pizza: [this.sessionForm.mix_flavors_pizza || '', [Validators.required]],
            add_pizza: [this.sessionForm?.add_pizza|| '', [Validators.required]]
        })
    }

    returnPage(){
        window.history.back()
    }

    createForm(){
        if(this.myForm.valid){
            const form = {
                pizza_price_calculation: this.myForm.value?.pizza_price_calculation,
                mix_flavors_pizza: this.myForm.value?.mix_flavors_pizza,
                add_pizza: this.myForm.value?.add_pizza,
            }
            
            if(this.sessionForm?.final_informations){
                this.establishmentService.updateForm(form);
                this.router.navigate(['final-informations/' + this.pageId]);
            } else {
                this.establishmentService.updateForm(form); 
                this.router.navigate(['address/' + this.pageId])
            }
        }
    }
}