import { Component, Input, OnInit } from '@angular/core'
import { LoaderService } from './loader.service'
@Component({
	selector: 'app-loader',
	templateUrl: './loader.component.html',
	styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit {
	public isLoading: boolean
	public message: string
	constructor(private loaderService: LoaderService) {
		this.loaderService.loaderChange.subscribe((data) => {
			this.isLoading = data.isLoading
			this.message = data.message
		})
	}

	ngOnInit(): void {}
}
