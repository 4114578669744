<div class="flow-home">
    <div class="container">
        <div class="img-container">
            <img src="../../assets/anotaai.png" alt="anotaai">
        </div>
        <div class="title-container">
            <p>Agora é só revisar suas informações!</p>
        </div>
        <div class="input-container">
            <div>
                <div class="header-informations">
                    <span>Dados do estabelecimento</span>
                </div>
                <div>
                    <div class="informations">
                        <div style="display: flex;">
                            <span>Nome do Assinante:</span>
                            <p>{{sessionForm?.name}}</p>
                        </div>
                        <a routerLink="/establishment/establishment-part-1/{{pageId}}"><anota-icon iconName="edit" iconSize="20px"></anota-icon></a>
                    </div>
                    <div class="informations">
                        <div style="display: flex;">
                            <span>CPF/CNPJ:</span>
                            <p>{{sessionForm?.cpf_cnpj}}</p>
                        </div>
                        <a routerLink="/establishment/establishment-part-1/{{pageId}}"><anota-icon iconName="edit" iconSize="20px"></anota-icon></a>
                    </div>
                    <div class="informations">
                        <div style="display: flex;">
                            <span>Razão Social: </span>
                            <p>{{sessionForm?.company_name}}</p>
                        </div>
                        <a routerLink="/establishment/establishment-part-1/{{pageId}}"><anota-icon iconName="edit" iconSize="20px"></anota-icon></a>
                    </div>
                    <div class="informations">
                        <div style="display: flex;">
                            <span>Nome Fantasia: </span>
                            <p>{{sessionForm?.fantasy_name}}</p>
                        </div>
                        <a routerLink="/establishment/establishment-part-1/{{pageId}}"><anota-icon iconName="edit" iconSize="20px"></anota-icon></a>
                    </div>
                    <div class="informations">
                        <div style="display: flex;">
                            <span>Logo: </span>
                            <p *ngIf="sessionForm?.logo && sessionForm?.logo != ''"><a [href]="sessionForm?.logo" target="_blank">https://onboarding-register...</a></p>
                            <p *ngIf="!sessionForm?.logo"> --</p>
                        </div>
                        <a routerLink="/establishment/establishment-part-3/{{pageId}}"><anota-icon iconName="edit" iconSize="20px"></anota-icon></a>
                    </div>
                    <div class="informations">
                        <div style="display: flex;">
                            <span>Contato: </span>
                            <p>{{sessionForm?.phone}}</p>
                        </div>
                        <a routerLink="/establishment/establishment-part-2/{{pageId}}"><anota-icon iconName="edit" iconSize="20px"></anota-icon></a>
                    </div>
                    <div class="informations">
                        <div>
                            <span>Segmento: </span>
                            <div *ngFor="let segment of sessionForm?.segment">
                                <p>{{segment.option}}</p>
                            </div>
                        </div>
                        <a routerLink="/establishment/establishment-part-2/{{pageId}}"><anota-icon iconName="edit" iconSize="20px"></anota-icon></a>
                    </div>
                    <br>
                </div>
            </div>
            <div>
                <div class="header-informations">
                    <span>Dados do cardápio</span>
                </div>
                <div>
                    <div class="informations">
                        <div style="align-items: center;">
                            <span>Arquivos/Fotos: </span>
                            <div *ngFor="let document of sessionForm?.menu_documents" >
                                <a [href]="document.url" target="_blank">https://onboarding-register.s3.am...</a>
                            </div>
                            <br>
                        </div>
                        <a routerLink="/establishment/menu/{{pageId}}"><anota-icon iconName="edit" iconSize="20px"></anota-icon></a>
                    </div>
                    <div class="informations">
                        <div style="overflow: hidden;white-space: nowrap;text-overflow: ellipsis;">
                            <span>Link Cardápio: </span>
                            <a [href]="sessionForm?.menu_link" target="_blank">{{sessionForm?.menu_link}}</a>
                        </div>
                        <a routerLink="/establishment/menu/{{pageId}}"><anota-icon iconName="edit" iconSize="20px"></anota-icon></a>
                    </div>
                </div>
            </div>
            <br>
            <div *ngIf="segmentPizza?.option == 'Pizzaria'">
                <div class="header-informations">
                    <span>Dados do cardápio de pizza</span>
                </div>
                <div>
                    <div class="informations">
                        <div>
                            <span>Tamanho de Pizza:</span>
                            <div *ngFor="let pizza of sessionForm?.sizes">
                                <p>{{pizza.title}} - Pedaços: {{pizza.amount_slice}} - Sabores: {{pizza.amount_flavor}}</p>
                            </div>
                        </div>
                        <a routerLink="/establishment/pizza/{{pageId}}"><anota-icon iconName="edit" iconSize="20px"></anota-icon></a>
                    </div>
                    <div class="informations">
                        <div>
                            <span>Mistura de sabores: </span>
                            <p>{{sessionForm?.mix_flavors_pizza}}</p>
                        </div>
                        <a routerLink="/establishment/pizza-part-3/{{pageId}}"><anota-icon iconName="edit" iconSize="20px"></anota-icon></a>
                    </div>
                    <div class="informations">
                        <div>
                            <span>Cálculo do valor das pizzas com mais de um sabor: </span>
                            <p>{{sessionForm?.pizza_price_calculation}}</p>
                        </div>
                        <a routerLink="/establishment/pizza-part-3/{{pageId}}"><anota-icon iconName="edit" iconSize="20px"></anota-icon></a>
                    </div>
                    <div class="informations">
                        <div>
                            <span>Adicionais são para inteiras ou sabor? </span>
                            <p>{{sessionForm?.add_pizza}}</p>
                        </div>
                        <a routerLink="/establishment/pizza-part-3/{{pageId}}"><anota-icon iconName="edit" iconSize="20px"></anota-icon></a>
                    </div>
                </div>
                <br>
            </div>
            <div>
                <div class="header-informations">
                    <span>Endereço do estabelecimento</span>
                </div>
                <div>
                    <div class="informations">
                        <div style="display: flex;">
                            <span>CEP:</span>
                            <p>{{sessionForm?.address?.postal_code}}</p>
                        </div>
                        <a routerLink="/address/{{pageId}}"><anota-icon iconName="edit" iconSize="20px"></anota-icon></a>
                    </div>
                    <div class="informations">
                        <div style="display: flex;">
                            <span>Logradouro: </span>
                            <p>{{sessionForm?.address?.street}}</p>
                        </div>
                        <a routerLink="/address/{{pageId}}"><anota-icon iconName="edit" iconSize="20px"></anota-icon></a>
                    </div>
                    <div class="informations">
                        <div style="display: flex;">
                            <span>Numero: </span>
                            <p>{{sessionForm?.address?.number}}</p>
                        </div>
                        <a routerLink="/address/{{pageId}}"><anota-icon iconName="edit" iconSize="20px"></anota-icon></a>
                    </div>
                    <div *ngIf="sessionForm?.address?.complement" class="informations">
                        <div style="display: flex;">
                            <span>Complemento: </span>
                            <p>{{sessionForm?.address?.complement}}</p>
                        </div>
                        <a routerLink="/address/{{pageId}}"><anota-icon iconName="edit" iconSize="20px"></anota-icon></a>
                    </div>
                    <div class="informations">
                        <div style="display: flex;">
                            <span>Bairro: </span>
                            <p>{{sessionForm?.address?.district}}</p>
                        </div>
                        <a routerLink="/address/{{pageId}}"><anota-icon iconName="edit" iconSize="20px"></anota-icon></a>
                    </div>
                    <div class="informations">
                        <div style="display: flex;">
                            <span>Cidade: </span>
                            <p>{{sessionForm?.address?.city}}</p>
                        </div>
                        <a routerLink="/address/{{pageId}}"><anota-icon iconName="edit" iconSize="20px"></anota-icon></a>
                    </div>
                    <div class="informations">
                        <div style="display: flex;">
                            <span>Estado: </span>
                            <p>{{sessionForm?.address?.state}}</p>
                        </div>
                        <a routerLink="/address/{{pageId}}"><anota-icon iconName="edit" iconSize="20px"></anota-icon></a>
                    </div>
                </div>
            </div>
            <br>
            <div>
                <div class="header-informations">
                    <span>Horário de funcionamento</span>
                </div>
                <div>
                    <div class="informations">
                        <div>
                            <span>Disponibilidade: </span>
                            <div *ngFor="let day of sessionForm?.week">
                                <div *ngFor="let schedule of day.schedules">
                                    <p>{{day.name}} - {{schedule.start}} a {{schedule.end}} </p>
                                </div>
                            </div>
                        </div>
                        <a routerLink="/operation-hours/{{pageId}}"><anota-icon iconName="edit" iconSize="20px"></anota-icon></a>
                    </div>
                </div>
            </div>
            <br>
            <div>
                <div class="header-informations">
                    <span>Informações sobre entrega</span>
                </div>
                <div>
                    <div class="informations">
                        <div>
                            <span>Tipo de entrega:</span>
                            <p *ngIf="sessionForm?.delivery">Delivery</p>
                            <p *ngIf="sessionForm?.consume_local">Consumo no local</p>
                            <p *ngIf="sessionForm?.take_local">Busca no local</p>
                        </div>
                        <a routerLink="/delivery-informations/{{pageId}}"><anota-icon iconName="edit" iconSize="20px"></anota-icon></a>
                    </div>
                    <div class="informations">
                        <div>
                            <span>Possui taxa mínima? </span>
                            <p>{{sessionForm?.order_fee}}</p>
                        </div>
                        <a routerLink="/delivery-informations-2/{{pageId}}"><anota-icon iconName="edit" iconSize="20px"></anota-icon></a>
                    </div>
                    <div *ngIf="sessionForm?.order_fee == 'Sim'" class="informations">
                        <div style="display: flex;">
                            <span>Valor: </span>
                            <p>R$ {{sessionForm?.order_fee_value.toFixed(2).replace('.',',')}}</p>
                        </div>
                        <a routerLink="/delivery-informations-2/{{pageId}}"><anota-icon iconName="edit" iconSize="20px"></anota-icon></a>
                    </div>
                </div>
            </div>
            <br>
            <div>
                <div class="header-informations">
                    <span>Regiões de atendimento</span>
                </div>
                <div>
                    <div class="informations">
                        <div>
                            <span>Modalidade de entrega:</span>
                            <p>{{sessionForm?.attendance_model}}</p>
                        </div>
                        <a routerLink="/attendance-location/{{pageId}}"><anota-icon iconName="edit" iconSize="20px"></anota-icon></a>
                    </div>
                    <div *ngIf="sessionForm?.attendance_model == 'Por Bairro'" class="informations">
                        <div>
                            <span>Bairros:</span>
                            <div *ngFor="let district of sessionForm?.attendance_district.options">
                                <p>{{district.name}} - R$ {{district?.value.toFixed(2).replace('.',',')}}</p>
                            </div>
                        </div>
                        <a routerLink="/attendance-location/{{pageId}}"><anota-icon iconName="edit" iconSize="20px"></anota-icon></a>
                    </div>
                    <div *ngIf="sessionForm?.attendance_model == 'Por Raio'" class="informations">
                        <div>
                            <span>Raios:</span>
                            <div *ngFor="let radius of sessionForm?.attendance_radius.options">
                                <p>{{radius.name}} (Km) - R$ {{radius?.value.toFixed(2).replace('.',',')}}</p>
                            </div>
                        </div>
                        <a routerLink="/attendance-location/{{pageId}}"><anota-icon iconName="edit" iconSize="20px"></anota-icon></a>
                    </div>
                    <div *ngIf="sessionForm?.attendance_model == 'Mesmo valor para qualquer localidade'" class="informations">
                        <div style="display: flex;">
                            <span>Valor: </span>
                            <p>R$ {{sessionForm?.attendance_city_value.toFixed(2).replace('.',',')}}</p>
                        </div>
                        <a routerLink="/attendance-location/{{pageId}}"><anota-icon iconName="edit" iconSize="20px"></anota-icon></a>
                    </div>
                </div>
            </div>
            <br>
            <div>
                <div class="header-informations">
                    <span>Formas de pagamento aceitas</span>
                </div>
                <div>
                    <div class="informations">
                        <div>
                            <span>Pagamento por: </span>
                            <div *ngFor="let payment of sessionForm?.payment_method">
                                <div *ngIf="payment.value == true">
                                    <p>{{payment.name}}</p>
                                </div>
                            </div>
                        </div>
                        <a routerLink="/payment-informations/{{pageId}}"><anota-icon iconName="edit" iconSize="20px"></anota-icon></a>
                    </div>
                    <div class="informations" *ngIf="cardList">
                        <div>
                            <span>Bandeiras do cartão aceitas: </span>
                            <div *ngFor="let card of sessionForm?.credit_cards">
                                <p>{{card.option}}</p>
                            </div>
                        </div>
                        <a routerLink="/payment-informations/{{pageId}}"><anota-icon iconName="edit" iconSize="20px"></anota-icon></a>
                    </div>
                </div>
            </div>
        <div>
            <br>
            <br>
            <div class="button">
                <anota-button size="lg" (click)="saveForm()">Concluir</anota-button>
            </div>
        </div>
    </div>
</div>
<app-footer class="footer"></app-footer>